<template>
    <v-tab-item :value="tab">
        <card-select-products-bulk
            v-if="isBulkOrder"
            v-model="orders"
            v-bind="{
                form,
                targetsAt,
                backwardText,
                targetsAtText,
            }"
            v-on="{
                input,
                backward,
                forward,
            }"
        />
        <card-select-products-unit
            v-else
            v-model="orders[0].products"
            v-bind="{
                form,
                targetsAt,
                backwardText,
                targetsAtText,
                hideTargetsAt,
                hideBackwardBtn,
            }"
            v-on="{
                input,
                backward,
                forward,
            }"
        />
    </v-tab-item>
</template>

<script>
import PageSection from "@/components/client/sub/page-section.vue";
import CardSelectProductsBulk from "./card-select-products-bulk.vue";
import CardSelectProductsUnit from "./card-select-products-unit.vue";

export default {
    components: {
        PageSection,
        CardSelectProductsBulk,
        CardSelectProductsUnit,
    },
    props: {
        tab: { type: String },
        form: { type: Object, default: () => ({}) },
        value: { type: Array, default: () => [] }, // orders
        isBulkOrder: { type: Boolean, default: false },
        targetsAt: { type: String },
        backwardText: { type: String, default: "입고일 수정" },
        targetsAtText: { type: String, default: "입고일" },
        hideTargetsAt: { type: Boolean, default: false },
        hideBackwardBtn: { type: Boolean, default: false },
    },
    data: () => ({
        orders: [{ products: [] }],
    }),
    computed: {
        disabled() {
            return !this.orders.length;
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.orders = JSON.parse(JSON.stringify(this.value));
        },
        input() {
            this.$emit("input", this.orders);
        },
        backward() {
            this.$emit("backward");
        },
        forward() {
            this.$emit("forward");
        },
    },
};
</script>

