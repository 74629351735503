<template>
    <v-card flat tile>
        <v-card-text class="pa-0">
            <h3 v-if="!hideTitle" class="tit py-20 mt-n10">선택한 상품</h3>
            <border-list no-gutters :class="{ 'border-list--grey': true, 'py-10': !dense }">
                <products-result-item v-for="item in items" :key="item._id" v-bind="{ value: item, dense, readonly }" @input="update" @remove="remove(item)" />
                <v-col v-if="!items.length"> 선택한 상품이 없습니다 </v-col>
            </border-list>
        </v-card-text>
    </v-card>
</template>

<script>
import BorderList from "../../list/border-list.vue";
import ProductsResultItem from "./products-result-item.vue";

export default {
    components: {
        BorderList,
        ProductsResultItem,
    },
    props: {
        value: { type: Array, default: () => [] },
        dense: { type: Boolean, default: false },
        readonly: { type: Boolean, default: false },
        hideTitle: { type: Boolean, default: false },
    },
    data: () => ({
        items: [],
    }),
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.items = JSON.parse(JSON.stringify(this.value));
        },
        emit() {
            this.$emit("input", this.items);
        },
        update(item) {
            let index = this.items.findIndex(({ _id }) => _id == item._id);
            if (index >= 0) this.items.splice(index, 1, item);
            else this.items = [...this.items, item];
            this.$nextTick(this.emit);
        },
        remove(item) {
            this.items = this.items.filter(({ _id }) => _id != item._id);
            this.$nextTick(this.emit);
        },
    },
};
</script>

<style>
</style>