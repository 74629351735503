<template>
    <client-page>
        <purchase-order-view-info v-bind="{ order }" />
        <purchase-order-view-sender v-bind="{ order }" />
        <purchase-order-view-files v-bind="{ order }" />
        <purchase-order-view-receiver v-bind="{ order }" />
        <purchase-order-view-products v-bind="{ order }" />
        <purchase-order-view-actions v-bind="{ order }" v-on="{ search }" />
    </client-page>
</template>

<script>
import api from "@/api";
import { mapMutations } from "vuex";
import { LEFT_NAV_MODES } from "@/store/ui-header";

import ClientPage from "@/pages/client/templates/ClientPage.vue";
import PurchaseOrderViewInfo from "@/components/client/mypage/purchase-order-view/purchase-order-view-info.vue";
import PurchaseOrderViewFiles from '@/components/client/mypage/purchase-order-view/purchase-order-view-files.vue';
import PurchaseOrderViewSender from "@/components/client/mypage/purchase-order-view/purchase-order-view-sender.vue";
import PurchaseOrderViewActions from "@/components/client/mypage/purchase-order-view/purchase-order-view-actions.vue";
import PurchaseOrderViewReceiver from "@/components/client/mypage/purchase-order-view/purchase-order-view-receiver.vue";
import PurchaseOrderViewProducts from "@/components/client/mypage/purchase-order-view/purchase-order-view-products.vue";

export default {
    components: {
        ClientPage,
        PurchaseOrderViewInfo,
        PurchaseOrderViewFiles,
        PurchaseOrderViewSender,
        PurchaseOrderViewActions,
        PurchaseOrderViewReceiver,
        PurchaseOrderViewProducts,
    },
    props: {
        _order: { type: String },
    },
    data: () => ({
        loading: false,
        order: {},
    }),
    mounted() {
        this.setLeftNavMode(LEFT_NAV_MODES.BACK);
        this.setTitle("발주 상세");

        this.search();
    },
    destroyed() {
        this.setLeftNavMode(LEFT_NAV_MODES.MENU);
        this.setTitle();
    },
    watch: {
        params() {
            this.search();
        },
        order() {
            let title = "발주이력 상세";
            if (this.order?.typeTextShorten) title += ` - ${this.order?.typeTextShorten}`;
            this.setTitle(title);
        },
    },
    methods: {
        ...mapMutations("ui", {
            setTitle: "header/setTitle",
            setLeftNavMode: "header/setLeftNavMode",
        }),
        async search() {
            this.loading = true;
            try {
                let { _order: _id } = this;
                var { order } = await api.v1.me.purchaseOrders.get({ _id });
                this.order = order;
            } catch (error) {
                console.error(error);
                alert(error.message);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped></style>
