var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "row--small"
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-select', {
    attrs: {
      "placeholder": "대분류",
      "items": _vm.categories0,
      "item-text": "name",
      "item-value": "_id",
      "outlined": "",
      "hide-details": "",
      "clearable": ""
    },
    model: {
      value: _vm.category0,
      callback: function callback($$v) {
        _vm.category0 = $$v;
      },
      expression: "category0"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-select', {
    attrs: {
      "placeholder": "중분류",
      "items": _vm.filteredSubCategories,
      "item-text": "name",
      "item-value": "_id",
      "disabled": !_vm.category0,
      "outlined": "",
      "hide-details": "",
      "clearable": ""
    },
    model: {
      value: _vm.category1,
      callback: function callback($$v) {
        _vm.category1 = $$v;
      },
      expression: "category1"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }