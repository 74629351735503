var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', _vm._b({}, 'v-row', _vm.$attrs, false), [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('p', {
    staticClass: "min-w-44px page-text page-text--lg font-weight-medium grey--text text--darken-4"
  }, [_vm._v(_vm._s(_vm.listName))])]), _c('v-col', [_c('p', {
    staticClass: "page-text page-text--lg"
  }, [_vm._v(_vm._s(_vm.listText))]), _vm._t("default")], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }