<template>
    <v-sheet tile>
        <h2 class="tit">보내시는 분</h2>
        <v-divider class="my-20" />
        <div v-if="!readonly" class="mb-20">
            <v-row>
                <v-col cols="12"> <v-text-field v-model="sender.name" outlined hide-details placeholder="이름" @input="emit" /> </v-col>
                <v-col cols="12"> <v-text-field v-model="sender.phone" outlined hide-details placeholder="연락처" @input="emit" /> </v-col>
            </v-row>
        </div>
        <div>
            <template v-if="readonly">
                <text-list> <text-list-item listName="이름" :listText="sender.name || '-'" /> </text-list>
                <text-list> <text-list-item listName="연락처" :listText="sender.phone || '-'" /> </text-list>
            </template>
            <text-list> <text-list-item listName="주소" :listText="sender.address || '-'" /> </text-list>
        </div>
    </v-sheet>
</template>

<script>
import { initSender } from "@/lib";

import TextList from "../list/text-list.vue";
import TextListItem from "../list/text-list-item.vue";
import VAddressField from "@/components/dumb/v-address-field.vue";

export default {
    components: {
        TextList,
        TextListItem,
        VAddressField,
    },
    props: {
        value: { type: Object, default: initSender }, // sender
        readonly: { type: Boolean, default: false },
    },
    data: () => ({
        sender: initSender(),
    }),
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.sender = JSON.parse(JSON.stringify(this.value));
        },
        emit() {
            this.$emit("input", this.sender);
        },
    },
};
</script>

<style>
</style>