<template>
    <v-dialog-default contentClass="align-self-start" ref="dialog">
        <template #activator="{ attrs, on }">
            <v-row v-bind="attrs" v-on="on" no-gutters>
                <v-col>
                    <v-text-field v-model="query.searchValue" outlined readonly hide-details placeholder="발주번호 / 상품명을 입력해주세요." />
                </v-col>
                <v-col cols="auto">
                    <v-btn x-large icon class="mr-n12 v-btn--none-active-bg"><v-icon size="26">search</v-icon></v-btn>
                </v-col>
            </v-row>
        </template>

        <div>
            <v-row no-gutters>
                <v-col>
                    <v-text-field v-model="query.searchValue" outlined clearable hide-details autofocus placeholder="발주번호 / 상품명을 입력하세요." @keydown.enter="setQuery" />
                </v-col>
                <v-col cols="auto">
                    <v-btn x-large icon class="mr-n12" @click="setQuery"> <v-icon size="26"> search </v-icon> </v-btn>
                </v-col>
            </v-row>
        </div>
        <div class="pt-20" style="position: relative">
            <div class="font-size-12 font-weight-medium mb-4">입고일</div>
            <v-row align="center" class="row--small">
                <v-col>
                    <v-date-field v-model="query.targetsAt[0]" append-icon="event" outlined hide-details placeholder="0000-00-00" right clearable />
                </v-col>
                <v-col cols="auto"><span class="page-text">~</span></v-col>
                <v-col>
                    <v-date-field v-model="query.targetsAt[1]" append-icon="event" outlined hide-details placeholder="0000-00-00" left clearable />
                </v-col>
            </v-row>
        </div>
        <div class="pt-20">
            <v-btn block color="primary" class="v-size--xx-large" @click="setQuery">조회</v-btn>
        </div>
    </v-dialog-default>
</template>

<script>
import VDateField from "@/components/plugins/vuetify/v-date-field.vue";
import VDialogDefault from "@/components/plugins/vuetify/v-dialog-default.vue";

let initQuery = () => ({
    searchValue: null,
    targetsAt: [null, null],
});

export default {
    components: {
        VDateField,
        VDialogDefault,
    },
    data: () => ({
        query: initQuery(),
    }),
    mounted() {
        this.sync();
    },
    methods: {
        sync() {
            this.query = JSON.parse(JSON.stringify({ ...initQuery(), ...this.$route.query }));
        },
        closeDialog() {
            this.$refs["dialog"]?.close?.();
        },
        setQuery() {
            let { query = {} } = this;
            if (!query.searchValue) delete query.searchValue;
            query.page = 1;
            this.$router.push({ query });
            this.closeDialog();
        },
    },
};
</script>

<style>
</style>