var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "scrollable": "",
      "max-width": "500px",
      "content-class": "v-dialog--default rounded-0"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var attrs = _ref.attrs,
            on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs: attrs,
          on: on
        })];
      }
    }], null, true),
    model: {
      value: _vm.shows,
      callback: function callback($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": "",
      "tile": ""
    }
  }, [_c('v-card-title', [_c('v-spacer'), _c('v-btn', {
    staticClass: "v-dialog--default__close mr-n12",
    attrs: {
      "icon": "",
      "tile": ""
    },
    on: {
      "click": function click($event) {
        _vm.shows = false;
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "size": "20"
    }
  }, [_vm._v("mdi-close")])], 1)], 1), _c('v-card-text', [_c('card-products-select', _vm._b({
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.products,
      callback: function callback($$v) {
        _vm.products = $$v;
      },
      expression: "products"
    }
  }, 'card-products-select', {
    form: _vm.form
  }, false))], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }