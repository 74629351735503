var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', {
    attrs: {
      "tile": ""
    }
  }, [_vm.$slots['header'] ? _vm._t("header") : _c('h2', {
    staticClass: "tit"
  }, [_vm._v("받으시는 분")]), _c('v-divider', {
    staticClass: "my-20"
  }), _vm.$slots['default'] ? _c('text-list', [_vm._l(_vm.listItems, function (item) {
    return _c('text-list-item', _vm._b({
      key: item.listName
    }, 'text-list-item', item, false));
  }), _vm._t("default")], 2) : _c('div', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "hide-details": "",
      "placeholder": "이름"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.receiver.name,
      callback: function callback($$v) {
        _vm.$set(_vm.receiver, "name", $$v);
      },
      expression: "receiver.name"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "hide-details": "",
      "placeholder": "연락처"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.receiver.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.receiver, "phone", $$v);
      },
      expression: "receiver.phone"
    }
  })], 1), _c('v-col', {
    staticClass: "pa-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-address-field', {
    attrs: {
      "outlined": "",
      "hide-details": "",
      "className": "pa-6",
      "btnAttrs": {
        xLarge: true,
        color: 'grey',
        className: 'w-100px'
      }
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.receiver,
      callback: function callback($$v) {
        _vm.receiver = $$v;
      },
      expression: "receiver"
    }
  })], 1)], 1)], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }