<template>
    <page-section v-if="items.length">
        <h2 class="tit">첨부파일</h2>
        <v-divider class="my-20" />
        <table class="table-default">
            <tr v-for="item in items" :key="item.text">
                <th :class="item.className">{{ item.text }}</th>
                <image-popup hasCustomActivator :src="item.src">
                    <template #activator="{ attrs, on }">
                        <td v-bind="attrs" v-on="on">{{ item.value }}</td>
                    </template>
                </image-popup>
            </tr>
        </table>
    </page-section>
</template>

<script>
import PageSection from "@/components/client/sub/page-section.vue";
import ImagePopup from "@/components/console/dumb/image-popup.vue";

export default {
    components: {
        PageSection,
        ImagePopup,
    },
    props: {
        order: { type: Object, default: () => ({}) },
    },
    computed: {
        items() {
            let items = [];
            let { receipt, businessCard, greetingCard } = this.order;
            if (receipt)
                items.push({
                    text: "영수증",
                    value: receipt?.fileName,
                    src: receipt.src,
                });
            if (businessCard)
                items.push({
                    text: "명함",
                    value: businessCard?.fileName,
                    src: businessCard.src,
                });
            if (greetingCard)
                items.push({
                    text: "인사장",
                    value: greetingCard?.fileName,
                    src: greetingCard.src,
                });
            return items;
        },
    },
};
</script>

<style>
</style>