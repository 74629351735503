var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('purchase-order-view-info', _vm._b({}, 'purchase-order-view-info', {
    order: _vm.order
  }, false)), _c('purchase-order-view-sender', _vm._b({}, 'purchase-order-view-sender', {
    order: _vm.order
  }, false)), _c('purchase-order-view-files', _vm._b({}, 'purchase-order-view-files', {
    order: _vm.order
  }, false)), _c('purchase-order-view-receiver', _vm._b({}, 'purchase-order-view-receiver', {
    order: _vm.order
  }, false)), _c('purchase-order-view-products', _vm._b({}, 'purchase-order-view-products', {
    order: _vm.order
  }, false)), _c('purchase-order-view-actions', _vm._g(_vm._b({}, 'purchase-order-view-actions', {
    order: _vm.order
  }, false), {
    search: _vm.search
  }))], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }