var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', [_c('page-section', {
    staticClass: "page-section--first"
  }, [_c('h2', {
    staticClass: "tit tit--lg"
  }, [_vm._v(_vm._s(_vm.title))]), !_vm.hideTargetsAt ? _c('p', {
    staticClass: "page-text page-text--lg font-weight-medium primary--text mt-8"
  }, [_vm._v(_vm._s(_vm.targetsAtText) + " : " + _vm._s(_vm.targetsAt))]) : _vm._e()]), _c('page-section', [_c('card-products-select', _vm._b({
    on: {
      "input": _vm.setProducts
    }
  }, 'card-products-select', {
    form: _vm.form
  }, false))], 1), _c('page-section', [_c('card-products-result', {
    model: {
      value: _vm.products,
      callback: function callback($$v) {
        _vm.products = $$v;
      },
      expression: "products"
    }
  })], 1), _c('page-section', [_c('v-row', {
    staticClass: "row--small"
  }, [!_vm.hideBackwardBtn ? _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-btn', {
    staticClass: "v-size--xx-large",
    attrs: {
      "block": "",
      "outlined": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('backward');
      }
    }
  }, [_vm._v(_vm._s(_vm.backwardText))])], 1) : _vm._e(), _c('v-col', {
    attrs: {
      "cols": _vm.hideBackwardBtn ? 12 : 6
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-size--xx-large",
    attrs: {
      "block": "",
      "color": "primary"
    },
    on: {
      "click": _vm.forward
    }
  }, 'v-btn', {
    disabled: _vm.disabled
  }, false), [_vm._v("선택 완료")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }