var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "row--small"
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.types,
      "item-text": "textShorten",
      "outlined": "",
      "hide-details": "",
      "placeholder": "접수유형",
      "clearable": ""
    },
    on: {
      "change": _vm.setQuery
    },
    model: {
      value: _vm.query.type,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "type", $$v);
      },
      expression: "query.type"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.states,
      "outlined": "",
      "hide-details": "",
      "placeholder": "접수상태",
      "clearable": ""
    },
    on: {
      "change": _vm.setQuery
    },
    model: {
      value: _vm.query.state,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "state", $$v);
      },
      expression: "query.state"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }