var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-tab-item', {
    attrs: {
      "value": _vm.tab
    }
  }, [_c('page-section', {
    staticClass: "page-section--first"
  }, [_c('h2', {
    staticClass: "tit tit--lg"
  }, [_vm._v(_vm._s(_vm.title))]), _c('p', {
    staticClass: "page-text page-text--lg font-weight-medium primary--text mt-8"
  }, [_vm._v("발송일 : " + _vm._s(_vm.targetsAt))])]), _c('page-section', [_c('card-sender', {
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.sender,
      callback: function callback($$v) {
        _vm.sender = $$v;
      },
      expression: "sender"
    }
  })], 1), _c('page-section', [_c('v-row', {
    staticClass: "row--small"
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-btn', {
    staticClass: "v-size--xx-large",
    attrs: {
      "block": "",
      "outlined": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('backward');
      }
    }
  }, [_vm._v("발송일 수정")])], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-size--xx-large",
    attrs: {
      "block": "",
      "color": "primary"
    },
    on: {
      "click": _vm.forward
    }
  }, 'v-btn', {
    disabled: _vm.disabled
  }, false), [_vm._v("입력 완료")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }