var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('page-section', {
    staticClass: "page-section--sm"
  }, [_c('v-row', {
    staticClass: "row--small"
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_vm.isCancelable ? _c('actions-cancel', _vm._b({
    on: {
      "canceled": function canceled($event) {
        return _vm.$emit('search');
      }
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var attrs = _ref.attrs,
            on = _ref.on;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "v-size--xx-large",
          attrs: {
            "block": "",
            "outlined": "",
            "color": "grey lighten-3"
          }
        }, 'v-btn', attrs, false), on), [_vm._v("발주 취소")])];
      }
    }], null, false, 2882800193)
  }, 'actions-cancel', {
    order: _vm.order
  }, false)) : _vm.isCanceled ? [_c('v-btn', {
    staticClass: "v-size--xx-large",
    attrs: {
      "block": "",
      "disabled": ""
    }
  }, [_vm._v("취소 완료")])] : _vm.isClaimReceived ? [_c('v-btn', {
    staticClass: "v-size--xx-large",
    attrs: {
      "block": "",
      "disabled": ""
    }
  }, [_vm._v("클레임")])] : [_c('v-btn', {
    staticClass: "v-size--xx-large",
    attrs: {
      "block": "",
      "disabled": ""
    }
  }, [_vm._v("발주 완료")])]], 2), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-btn', {
    staticClass: "v-size--xx-large",
    attrs: {
      "block": "",
      "outlined": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v("리스트")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }