<template>
    <page-section class="page-section--first">
        <table class="table-default">
            <tr v-for="item in items" :key="item.text">
                <th :class="item.className">{{ item.text }}</th>
                <td>{{ item.value }}</td>
            </tr>
        </table>
    </page-section>
</template>

<script>
import PageSection from "@/components/client/sub/page-section.vue";

import { PURCHASE_ORDER_TYPES } from "@/assets/variables";

export default {
    components: {
        PageSection,
    },
    props: {
        order: { type: Object, default: () => ({}) },
    },
    computed: {
        targetsAtItem() {
            let { CUSTOMER_ORDER_BY_UNIT, CUSTOMER_ORDER_BY_BULK, BUNDANG_HANDLING_ORDER, STORE_ORDER_BY_COURIER, STORE_ORDER_BY_EXPRESS } = PURCHASE_ORDER_TYPES;

            let isDateHidden = this.isOrderType(BUNDANG_HANDLING_ORDER, STORE_ORDER_BY_COURIER, STORE_ORDER_BY_EXPRESS);
            if (isDateHidden) return;
            else {
                let isSendingDate = this.isOrderType(CUSTOMER_ORDER_BY_UNIT, CUSTOMER_ORDER_BY_BULK);
                let targetsAtName = isSendingDate ? "발송일" : "입고일";
                return { text: targetsAtName, value: this.order?.targetsAt?.toDate() || "-" };
            }
        },
        items() {
            let items = [];

            items.push({ text: "발주번호", value: this.order?.number || "-" });
            items.push({ text: "발주일", value: this.order?.createdAt?.toDate() || "-" });
            if (this.targetsAtItem) items.push(this.targetsAtItem);
            items.push({ text: "접수상태", value: this.order?.stateText || "-" });

            let activeClaim = [...(this.order?.claims || [])].pop();
            if (activeClaim) {
                let { _product, handlerName, query, reply } = activeClaim;
                let product = this.order.products.find(({ _id }) => _id == _product);
                let claims = [
                    { className: "red--text", text: "상품명", value: product?.name || "-" },
                    { className: "red--text", text: "클레임", value: query || "-" },
                    { className: "red--text", text: "처리", value: reply || "-" },
                    { className: "red--text", text: "담당자", value: handlerName || "-" },
                ];

                items = items.concat(claims);
            }

            return items;
        },
    },
    methods: {
        isOrderType(...items) {
            return items.map(({ value }) => value).includes(this.order.type);
        },
    },
};
</script>

<style>
</style>