var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', _vm._b({
    attrs: {
      "max-width": "80vmin",
      "width": "420",
      "persistent": ""
    }
  }, 'v-dialog', {
    value: _vm.value
  }, false), [_c('v-card', [_c('v-card-text', {
    staticClass: "pa-20",
    staticStyle: {
      "white-space": "pre-line"
    }
  }, [_vm._v(" " + _vm._s(_vm.message) + " ")]), _c('v-divider'), _c('v-card-actions', {
    staticClass: "pa-0"
  }, [_c('v-btn', {
    attrs: {
      "plain": "",
      "text": "",
      "block": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v("확인")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }