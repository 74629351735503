<template>
    <client-page>
        <page-section class="page-section--first page-section--sm">
            <purchase-order-list-search />
        </page-section>

        <page-section>
            <purchase-order-list-filter />

            <border-list class="pt-20">
                <purchase-order-list-item v-for="item in items" v-bind="{ item }" :key="item._id" />
            </border-list>

            <v-btn v-if="summary.totalCount && orders.length < summary.totalCount" block outlined color="primary" class="mt-20" v-bind="{ loading }" @click="$router.replace({ query: { ...$route.query, page: page + 1 } })">더 보기</v-btn>
        </page-section>
    </client-page>
</template>

<script>
import api from "@/api";
import { mapMutations } from "vuex";
import { LEFT_NAV_MODES } from "@/store/ui-header";

import BorderList from "@/components/client/list/border-list.vue";
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import PageSection from "@/components/client/sub/page-section.vue";
import PurchaseOrderListItem from "@/components/client/mypage/purchase-order-list/purchase-order-list-item.vue";
import PurchaseOrderListSearch from "@/components/client/mypage/purchase-order-list/purchase-order-list-search.vue";
import PurchaseOrderListFilter from "@/components/client/mypage/purchase-order-list/purchase-order-list-filter.vue";

export default {
    components: {
        BorderList,
        ClientPage,
        PageSection,
        PurchaseOrderListItem,
        PurchaseOrderListSearch,
        PurchaseOrderListFilter,
    },
    data: () => ({
        loading: false,
        itemsPerPage: 10,
        isInitialSearch: true,

        orders: [],
        summary: { totalCount: 0 },
    }),
    computed: {
        page() {
            return +(this.$route.query.page || "1");
        },
        limit() {
            return this.isInitialSearch ? this.page * this.itemsPerPage : this.itemsPerPage;
        },
        skip() {
            if (this.isInitialSearch) return 0;
            else return (this.page - 1) * this.limit;
        },
        items() {
            return this.orders.map((item, index) => ({ ...item, index: this.summary.totalCount - (this.page - 1) * this.limit - index }));
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            return {
                searchValue: this.$route.query.searchValue || null,
                targetsAt: this.$route.query.targetsAt || [null, null],
                type: this.$route.query.type || null,
                state: this.$route.query.state || null,
            };
        },
    },
    mounted() {
        this.setLeftNavMode(LEFT_NAV_MODES.HOME);
        this.setTitle("발주이력");

        this.search();
    },
    destroyed() {
        this.setLeftNavMode(LEFT_NAV_MODES.MENU);
        this.setTitle();
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        ...mapMutations("ui", {
            setTitle: "header/setTitle",
            setLeftNavMode: "header/setLeftNavMode",
        }),
        async search() {
            this.loading = true;
            try {
                let { skip, limit, params } = this;
                var { summary, orders } = await api.v1.me.purchaseOrders.gets({
                    headers: { skip, limit },
                    params,
                });

                if (this.page == 1) this.orders = orders;
                else this.orders = this.orders.concat(...orders);

                this.summary = summary;
                if (this.isInitialSearch) this.isInitialSearch = false;
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.email);
                else alert(error.email.toString());
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped></style>
