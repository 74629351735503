<template>
    <client-page>
        <v-tabs-items :value="tab">
            <tab-select-type-customer
                v-model="form.type"
                :tab="tabs[0]"
                v-on="{
                    input: forward,
                }"
            />
            <tab-select-targets-at
                v-model="form.targetsAt"
                :tab="tabs[1]"
                title="발송일을 선택해 주세요."
                hasType
                v-on="{
                    backward,
                    forward,
                }"
            />
            <tab-input-sender
                v-model="form.sender"
                :tab="tabs[2]"
                v-bind="{ targetsAt }"
                v-on="{
                    backward,
                    forward,
                }"
            />
            <tab-input-receivers
                v-model="orders"
                :tab="tabs[3]"
                v-bind="{ targetsAt, isBulkOrder }"
                v-on="{
                    backward,
                    forward,
                }"
            />
            <tab-select-products
                v-model="orders"
                :tab="tabs[4]"
                targetsAtText="발송일"
                backwardText="받으시는 분 수정"
                v-bind="{ form, targetsAt, isBulkOrder }"
                v-on="{
                    backward,
                    forward,
                }"
            />
            <tab-attach-files
                v-model="form"
                :tab="tabs[5]"
                targetsAtText="발송일"
                v-bind="{ targetsAt }"
                v-on="{
                    backward,
                    forward,
                }"
            />
            <tab-confirm-customer
                v-bind="{ form, orders, loading }"
                :tab="tabs[6]"
                v-on="{
                    backward,
                    forward: post,
                }"
            />
        </v-tabs-items>
        <alert-dialog v-model="shows" v-bind="{ message }" v-on="{ close }" />
    </client-page>
</template>

<script>
import api from "@/api";
import { mapMutations } from "vuex";
import { initReceiver, initSender } from "@/lib";
import { PURCHASE_ORDER_TYPES } from "@/assets/variables";
import { LEFT_NAV_MODES } from "@/store/ui-header";

import ClientPage from "@/pages/client/templates/ClientPage.vue";
import PageSection from "@/components/client/sub/page-section.vue";
import AlertDialog from "@/components/client/dumb/alert-dialog.vue";
import TabInputSender from "@/components/client/purchase-ordering/tab-input-sender.vue";
import TabAttachFiles from "@/components/client/purchase-ordering/tab-attach-files.vue";
import TabInputReceivers from "@/components/client/purchase-ordering/tab-input-receivers.vue";
import TabSelectProducts from "@/components/client/purchase-ordering/tab-select-products/tab-select-products.vue";
import TabSelectTargetsAt from "@/components/client/purchase-ordering/tab-select-targets-at.vue";
import TabConfirmCustomer from "@/components/client/purchase-ordering/tab-confirm-customer.vue";
import TabSelectTypeCustomer from "@/components/client/purchase-ordering/tab-select-type-customer.vue";

let initForm = () => ({
    type: undefined,
    sender: initSender(),
    message: null,
    targetsAt: null,

    _receipt: undefined,
    _businessCard: undefined,
    _greetingCard: undefined,

    receipt: null,
    businessCard: null,
    greetingCard: null,
});
let pagePath = "/purchase-ordering/customer-order";
let tabs = ["select-type", "select-targets-at", "input-sender", "input-receivers", "select-products", "attach-files", "confirm-orders"];

export default {
    components: {
        ClientPage,
        PageSection,
        AlertDialog,
        TabInputSender,
        TabAttachFiles,
        TabInputReceivers,
        TabSelectProducts,
        TabSelectTargetsAt,
        TabConfirmCustomer,
        TabSelectTypeCustomer,
    },
    props: {
        tab: { type: String, default: tabs[0] },
    },
    data: () => ({
        tabs,
        shows: false,
        isDone: false,
        loading: false,
        succeeded: undefined,
        message: "",

        form: initForm(),
        orders: [
            {
                tempId: Math.random(),
                receiver: initReceiver(),
                products: [],
            },
        ],

        PURCHASE_ORDER_TYPES,
    }),
    computed: {
        isBulkOrder() {
            return this.form.type == PURCHASE_ORDER_TYPES.CUSTOMER_ORDER_BY_BULK.value;
        },
        pageName() {
            let pageName = "택배접수";
            let { CUSTOMER_ORDER_BY_UNIT, CUSTOMER_ORDER_BY_BULK } = PURCHASE_ORDER_TYPES;
            switch (this.form.type) {
                case CUSTOMER_ORDER_BY_UNIT.value: {
                    pageName += "(건별)";
                    break;
                }
                case CUSTOMER_ORDER_BY_BULK.value: {
                    pageName += "(일괄)";
                    break;
                }
            }
            return pageName;
        },
        targetsAt() {
            return this.form.targetsAt;
        },
    },
    mounted() {
        this.setLeftNavMode(LEFT_NAV_MODES.BACK);
        this.setTitle("택배접수 - 접수유형선택");
        this.reroute();
    },
    destroyed() {
        this.setLeftNavMode(LEFT_NAV_MODES.MENU);
        this.setTitle();
    },
    watch: {
        tab() {
            switch (this.tab) {
                case "select-type":
                    this.setTitle(`${this.pageName} - 접수유형선택`);
                    break;
                case "select-targets-at":
                    this.setTitle(`${this.pageName} - 발송일선택`);
                    break;
                case "input-sender":
                case "input-receivers":
                    this.setTitle(`${this.pageName} - 발송정보입력`);
                    break;
                case "select-products":
                    this.setTitle(`${this.pageName} - 상품선택`);
                    break;
                case "attach-files":
                    this.setTitle(`${this.pageName} - 파일첨부`);
                    break;
                case "confirm-orders":
                    this.setTitle(`${this.pageName} - 발주내용확인`);
                    break;
                default:
                    this.setTitle(`${this.pageName}`);
                    break;
            }
        },
    },
    methods: {
        ...mapMutations("ui", {
            setTitle: "header/setTitle",
            setLeftNavMode: "header/setLeftNavMode",
        }),
        reroute() {
            let path = `${pagePath}/${this.tab}`;
            if (this.$route.path != path) {
                this.$router.replace({ path });
            }
        },
        backward() {
            this.$router.go(-1);
        },
        forward() {
            let thisTabIndex = tabs.findIndex((item) => item == this.tab);
            let nextTab = tabs[thisTabIndex + 1];
            let path = `${pagePath}/${nextTab}`;
            this.$router.push({ path });
        },
        close() {
            this.shows = false;
            if (this.isDone) {
                let path = this.succeeded ? "/mypage/purchase-orders" : "/";
                this.$router.push({ path });
            }
        },
        async post() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { receipt, businessCard, greetingCard, ...form } = this.form;

                if (receipt) {
                    receipt = (await api.v1.me.purchaseOrders.files.post(receipt))?.file;
                    form._receipt = receipt._id;
                } else delete form._receipt;

                if (businessCard) {
                    businessCard = (await api.v1.me.purchaseOrders.files.post(businessCard))?.file;
                    form._businessCard = businessCard._id;
                } else delete form._businessCard;

                if (greetingCard) {
                    greetingCard = (await api.v1.me.purchaseOrders.files.post(greetingCard))?.file;
                    form._greetingCard = greetingCard._id;
                } else delete form._greetingCard;

                if (this.isBulkOrder) {
                    for (let { receiver, products } of this.orders) {
                        await api.v1.me.purchaseOrders.post({ ...form, receiver, products });
                    }
                } else {
                    let { receiver, products } = this.orders[0] || {};
                    await api.v1.me.purchaseOrders.post({ ...form, receiver, products });
                }

                this.message = "발주가 완료되었습니다.";
                this.succeeded = true;
            } catch (error) {
                this.message = error?.message;
                this.succeeded = false;
            } finally {
                this.loading = false;
                this.isDone = true;
                this.shows = true;
            }
        },
    },
};
</script>
