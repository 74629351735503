<template>
    <v-tab-item :value="tab">
        <page-section class="page-section--first">
            <h2 class="tit tit--lg">파일을 첨부해 주세요.</h2>
            <p class="page-text page-text--lg font-weight-medium primary--text mt-8">{{ targetsAtText }} : {{ targetsAt }}</p>
        </page-section>

        <page-section>
            <card-files v-bind="{ value }" v-on="{ input }" />
        </page-section>

        <page-section>
            <v-row class="row--small">
                <v-col cols="6">
                    <v-btn block outlined color="primary" class="v-size--xx-large" @click="$emit('backward')">상품 수정</v-btn>
                </v-col>
                <v-col cols="6">
                    <v-btn block color="primary" class="v-size--xx-large" @click="forward"> 첨부 완료 </v-btn>
                </v-col>
            </v-row>
        </page-section>
    </v-tab-item>
</template>

<script>
import CardFiles from "./card-files.vue";
import PageSection from "@/components/client/sub/page-section.vue";

export default {
    components: {
        CardFiles,
        PageSection,
    },
    props: {
        tab: { type: String },
        value: { type: Object, default: () => ({}) }, // form
        targetsAt: { type: String },
        targetsAtText: { type: String, default: "입고일" },
    },
    computed: {
        disabled() {
            return !this.value.receipt;
        },
    },
    methods: {
        input(form) {
            this.$emit("input", form);
        },
        forward() {
            try {
                if (this.disabled) throw new Error("영수증을 첨부한 뒤 진행해주세요.");
                this.$emit("forward");
            } catch (error) {
                alert(error.message);
                return;
            }
        },
    },
};
</script>

