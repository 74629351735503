var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-tab-item', {
    attrs: {
      "value": _vm.tab
    }
  }, [_c('page-section', {
    staticClass: "page-section--first"
  }, [_c('h2', {
    staticClass: "tit tit--lg"
  }, [_vm._v("발주 내용을 확인해주세요.")]), !_vm.hideTargetsAt ? _c('p', {
    staticClass: "page-text page-text--lg font-weight-medium primary--text mt-8"
  }, [_vm._v("입고일 : " + _vm._s(_vm.targetsAt))]) : _vm._e()]), _c('page-section', [_c('card-products-result', {
    attrs: {
      "value": _vm.products,
      "readonly": ""
    }
  })], 1), _c('page-section', [_c('v-row', {
    staticClass: "row--small"
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-size--xx-large",
    attrs: {
      "block": "",
      "outlined": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('backward');
      }
    }
  }, 'v-btn', {
    loading: _vm.loading
  }, false), [_vm._v("상품 수정")])], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-size--xx-large",
    attrs: {
      "block": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('forward');
      }
    }
  }, 'v-btn', {
    loading: _vm.loading
  }, false), [_vm._v(" 발주 완료 ")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }