var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-tab-item', {
    attrs: {
      "value": _vm.tab
    }
  }, [_c('page-section', {
    staticClass: "page-section--first"
  }, [_c('v-row', _vm._l(_vm.items, function (_ref) {
    var text = _ref.text,
        value = _ref.value;
    return _c('v-col', {
      key: text,
      attrs: {
        "cols": "6"
      }
    }, [_c('v-btn', {
      staticClass: "px-22",
      attrs: {
        "block": "",
        "height": "64",
        "color": "secondary"
      },
      on: {
        "click": function click($event) {
          return _vm.$emit('input', value);
        }
      }
    }, [_c('div', {
      staticClass: "d-flex align-center justify-space-between w-100"
    }, [_c('strong', {
      staticClass: "font-size-20"
    }, [_vm._v(_vm._s(text))]), _c('v-icon', {
      staticClass: "mr-n8",
      attrs: {
        "size": "26"
      }
    }, [_vm._v("mdi-chevron-right")])], 1)])], 1);
  }), 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }