var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-tab-item', {
    attrs: {
      "value": _vm.tab
    }
  }, [_vm.isBulkOrder ? _c('card-select-products-bulk', _vm._g(_vm._b({
    model: {
      value: _vm.orders,
      callback: function callback($$v) {
        _vm.orders = $$v;
      },
      expression: "orders"
    }
  }, 'card-select-products-bulk', {
    form: _vm.form,
    targetsAt: _vm.targetsAt,
    backwardText: _vm.backwardText,
    targetsAtText: _vm.targetsAtText
  }, false), {
    input: _vm.input,
    backward: _vm.backward,
    forward: _vm.forward
  })) : _c('card-select-products-unit', _vm._g(_vm._b({
    model: {
      value: _vm.orders[0].products,
      callback: function callback($$v) {
        _vm.$set(_vm.orders[0], "products", $$v);
      },
      expression: "orders[0].products"
    }
  }, 'card-select-products-unit', {
    form: _vm.form,
    targetsAt: _vm.targetsAt,
    backwardText: _vm.backwardText,
    targetsAtText: _vm.targetsAtText,
    hideTargetsAt: _vm.hideTargetsAt,
    hideBackwardBtn: _vm.hideBackwardBtn
  }, false), {
    input: _vm.input,
    backward: _vm.backward,
    forward: _vm.forward
  }))], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }