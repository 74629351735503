<template>
    <v-row class="row--small">
        <v-col cols="6">
            <v-select v-model="query.type" :items="types" item-text="textShorten" outlined hide-details placeholder="접수유형" clearable @change="setQuery" />
        </v-col>
        <v-col cols="6">
            <v-select v-model="query.state" :items="states" outlined hide-details placeholder="접수상태" clearable @change="setQuery" />
        </v-col>
    </v-row>
</template>

<script>
import { PURCHASE_ORDER_STATES, PURCHASE_ORDER_TYPES } from "@/assets/variables";

let initQuery = () => ({
    type: null,
    state: null,
});

let types = Object.values(PURCHASE_ORDER_TYPES);
let states = Object.values(PURCHASE_ORDER_STATES);

export default {
    data: () => ({
        query: initQuery(),

        types,
        states,
    }),
    mounted() {
        this.sync();
    },
    methods: {
        sync() {
            this.query = {
                ...initQuery(),
                ...this.$route.query,
            };
        },
        setQuery() {
            let { query = {} } = this;
            if (!query.type) delete query.type;
            if (!query.state) delete query.state;
            query.page = 1;
            this.$router.push({ query });
        },
    },
};
</script>

<style>
</style>