<template>
    <v-sheet>
        <page-section class="page-section--first">
            <h2 class="tit tit--lg">{{ title }}</h2>
            <p class="page-text page-text--lg font-weight-medium primary--text mt-8">{{ targetsAtText }} : {{ targetsAt }}</p>
        </page-section>

        <page-section class="page-section--sm">
            <v-row align="center" justify="space-between">
                <v-col cols="auto">
                    <v-btn text class="pa-0 grey--text" @click.prevent.stop.capture="toggleSelectedAll">
                        <v-simple-checkbox hide-details color="primary" :value="isAllSelected" @click.prevent />
                        전체선택
                    </v-btn>
                </v-col>
                <v-col cols="auto">
                    <dialog-products-select v-bind="{ form }" @input="setSelectedProducts">
                        <template #activator="{ attrs, on }">
                            <v-btn v-bind="attrs" v-on="on" outlined block color="primary" :disabled="!selected.length">일괄 상품 선택</v-btn>
                        </template>
                    </dialog-products-select>
                </v-col>
            </v-row>
        </page-section>

        <page-section v-for="order in orders" :key="order.tempId">
            <card-orders-result :value="order" @input="setOrder">
                <template #header>
                    <v-row align="center" justify="space-between">
                        <v-col cols="auto">
                            <v-btn text class="pa-0 grey--text" @click.prevent.stop.capture="toggleSelected(order)">
                                <v-simple-checkbox hide-details color="primary" :value="selected.includes(order.tempId)" @click.prevent />
                                <h3 class="tit grey--text text--darken-4">받으시는 분</h3>
                            </v-btn>
                        </v-col>
                        <v-col cols="auto">
                            <dialog-products-select v-bind="{ form }" @input="(items) => setProducts(items, order.tempId)">
                                <template #activator="{ attrs, on }">
                                    <v-btn outlined color="primary" v-bind="attrs" v-on="on">상품선택</v-btn>
                                </template>
                            </dialog-products-select>
                        </v-col>
                    </v-row>
                </template>
            </card-orders-result>
        </page-section>

        <page-section>
            <v-row class="row--small">
                <v-col cols="6">
                    <v-btn block outlined color="primary" class="v-size--xx-large" @click="$emit('backward')">{{ backwardText }}</v-btn>
                </v-col>
                <v-col cols="6">
                    <v-btn block color="primary" class="v-size--xx-large" v-bind="{ disabled }" @click="forward">선택 완료</v-btn>
                </v-col>
            </v-row>
        </page-section>
    </v-sheet>
</template>

<script>
import PageSection from "@/components/client/sub/page-section.vue";
import CardOrdersResult from "../card-orders-result/card-orders-result.vue";
import DialogProductsSelect from "../dialog-products-select.vue";

export default {
    components: {
        PageSection,
        CardOrdersResult,
        DialogProductsSelect,
    },
    props: {
        form: { type: Object, default: () => ({}) },
        value: { type: Array, default: () => [] }, // orders
        title: { type: String, default: "상품을 선택해 주세요." },
        targetsAt: { type: String },
        backwardText: { type: String, default: "받으시는 분 수정" },
        targetsAtText: { type: String, default: "입고일" },
    },
    data: () => ({
        orders: [],
        selected: [],
    }),
    computed: {
        disabled() {
            return this.orders.some(({ products }) => !products.length);
        },
        isAllSelected() {
            return !this.orders.some(({ tempId }) => !this.selected.includes(tempId));
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.orders = JSON.parse(JSON.stringify(this.value));
        },
        emit() {
            this.$emit("input", [...this.orders]);
        },
        toggleSelected({ tempId }) {
            if (!this.selected.includes(tempId)) this.selected.push(tempId);
            else this.selected = this.selected.filter((item) => item != tempId);
        },
        toggleSelectedAll() {
            if (this.isAllSelected) this.selected = [];
            else this.selected = this.orders.map(({ tempId }) => tempId);
        },

        setSelectedProducts(items) {
            for (const tempId of this.selected) {
                this.setProducts(items, tempId);
            }
        },

        setProducts(items, tempId) {
            let index = this.orders.findIndex((order) => order.tempId == tempId);

            if (index >= 0) {
                let order = JSON.parse(JSON.stringify(this.orders[index]));
                let { products = [] } = order;
                for (const item of items) {
                    let index = products.findIndex(({ _id }) => _id == item._id);
                    if (index >= 0) products[index].quantity += +item.quantity;
                    else products.push(item);
                }
                order.products = products;
                this.setOrder(order);
            }
        },

        setOrder(item) {
            let index = this.orders.findIndex(({ tempId }) => tempId == item.tempId);
            if (index >= 0) {
                this.orders.splice(index, 1, item);
                this.orders = [...this.orders];
                this.emit();
            }
        },

        forward() {
            try {
                if (this.disabled) throw new Error(this.title);
                this.$emit("forward");
            } catch (error) {
                alert(error.message);
                return;
            }
        },
    },
};
</script>

