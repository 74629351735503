var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.items.length ? _c('page-section', [_c('h2', {
    staticClass: "tit"
  }, [_vm._v("첨부파일")]), _c('v-divider', {
    staticClass: "my-20"
  }), _c('table', {
    staticClass: "table-default"
  }, _vm._l(_vm.items, function (item) {
    return _c('tr', {
      key: item.text
    }, [_c('th', {
      class: item.className
    }, [_vm._v(_vm._s(item.text))]), _c('image-popup', {
      attrs: {
        "hasCustomActivator": "",
        "src": item.src
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref) {
          var attrs = _ref.attrs,
              on = _ref.on;
          return [_c('td', _vm._g(_vm._b({}, 'td', attrs, false), on), [_vm._v(_vm._s(item.value))])];
        }
      }], null, true)
    })], 1);
  }), 0)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }