<template>
    <div>
        <h3 class="tit py-20">{{ title ?? "상품을 검색해주세요" }}</h3>
        <border-list no-gutters class="border-list--grey py-10">
            <products-select-list-item v-for="(item, index) in items" :key="item._id" :value="item" @input="(item) => items.splice(index, 1, item)" />
            <border-list-item v-if="title && items.length == 0"> 검색결과가 없습니다 </border-list-item>
        </border-list>
        <v-btn outlined block color="primary" class="v-size--xx-large" v-bind="{ disabled }" @click="select">상품담기</v-btn>
    </div>
</template>

<script>
import BorderList from "../../list/border-list.vue";
import BorderListItem from "../../list/border-list-item.vue";
import ProductsSelectListItem from "./products-select-list-item.vue";

export default {
    components: {
        BorderList,
        BorderListItem,
        ProductsSelectListItem,
    },
    props: {
        title: { type: String },
        products: { type: Array, default: () => [] },
    },
    data: () => ({
        items: [],
    }),
    computed: {
        selected() {
            return this.items.filter(({ quantity }) => quantity > 0);
        },
        disabled() {
            return !this.products.length || !this.selected.length;
        },
    },
    watch: {
        products() {
            this.items = this.products.map(({ _id, name, size, code, codeAlt, quantity = 0 }) => ({ _id, name, size, code, codeAlt, quantity }));
        },
    },
    methods: {
        select() {
            this.$emit("input", [...this.selected]);
        },
    },
};
</script>

<style>
</style>