<template>
    <div class="text-list">
        <slot />
    </div>
</template>

<script>
export default {
    props: {
    },
    components: {},
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.text-list,
::v-deep.text-list .page-text{
    line-height: 1.6;
}
.text-list >.row:not(:first-child){
    margin-top: -8px;
}
</style>
