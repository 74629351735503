var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('page-section', [_c('h2', {
    staticClass: "tit"
  }, [_vm._v("상품")]), _c('v-divider', {
    staticClass: "my-20"
  }), _vm._l(_vm.order.products, function (item) {
    return _c('table', {
      key: item._id,
      staticClass: "table-default"
    }, [_c('tr', [_c('th', [_vm._v("상품명")]), _c('td', [_vm._v(_vm._s(item.name))])]), _c('tr', [_c('th', [_vm._v("수량")]), _c('td', [_vm._v(_vm._s(item.quantity) + "개")])])]);
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }