var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('border-list-item', _vm._b({}, 'border-list-item', {
    dense: _vm.dense
  }, false), [_c('v-row', {
    attrs: {
      "justify": "space-between",
      "align": "center"
    }
  }, [_c('v-col', [_c('p', {
    staticClass: "page-text font-weight-bold grey--text text--darken-4"
  }, [_vm._v(_vm._s(_vm.item.name))])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm.readonly ? [_vm._v(_vm._s(_vm.item.quantity))] : _c('v-row', {
    staticClass: "input-number",
    attrs: {
      "no-gutters": "",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "x-small": "",
      "icon": "",
      "outlined": "",
      "color": "grey lighten-3"
    },
    on: {
      "click": _vm.decrease
    }
  }, [_c('v-icon', {
    attrs: {
      "size": "18",
      "color": "grey"
    }
  }, [_vm._v(" mdi-minus ")])], 1)], 1), _c('v-col', [_c('v-text-field', {
    staticClass: "input-number__num",
    attrs: {
      "type": "number",
      "min": "0",
      "dense": "",
      "outlined": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.item.quantity,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "quantity", $$v);
      },
      expression: "item.quantity"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "x-small": "",
      "icon": "",
      "outlined": "",
      "color": "grey lighten-3"
    },
    on: {
      "click": _vm.increase
    }
  }, [_c('v-icon', {
    attrs: {
      "size": "18",
      "color": "grey"
    }
  }, [_vm._v("mdi-plus")])], 1)], 1)], 1)], 2), !_vm.readonly ? _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "x-small": "",
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('remove');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "size": "18",
      "color": "grey"
    }
  }, [_vm._v("mdi-close")])], 1)], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }