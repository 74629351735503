<template>
    <client-page>
        <v-tabs-items :value="tab">
            <tab-select-products
                :tab="tabs[0]"
                :value="[form]"
                hideTargetsAt
                hideBackwardBtn
                v-bind="{ form, targetsAt }"
                v-on="{
                    input: (orders) => (form = orders[0]),
                    forward,
                }"
            />
            <tab-confirm-order-default
                :tab="tabs[1]"
                hideTargetsAt
                v-bind="{ targetsAt, products, loading }"
                v-on="{
                    backward,
                    forward: post,
                }"
            />
        </v-tabs-items>
        <alert-dialog v-model="shows" v-bind="{ message }" v-on="{ close }" />
    </client-page>
</template>

<script>
import api from "@/api";
import { mapMutations } from "vuex";
import { PURCHASE_ORDER_TYPES } from "@/assets/variables";
import { LEFT_NAV_MODES } from "@/store/ui-header";

import ClientPage from "@/pages/client/templates/ClientPage.vue";
import PageSection from "@/components/client/sub/page-section.vue";
import AlertDialog from "@/components/client/dumb/alert-dialog.vue";
import TabSelectProducts from "@/components/client/purchase-ordering/tab-select-products/tab-select-products.vue";
import TabConfirmOrderDefault from "@/components/client/purchase-ordering/tab-confirm-order-default.vue";

let initForm = () => ({
    type: PURCHASE_ORDER_TYPES.BUNDANG_HANDLING_ORDER.value,
    products: [],
});
let pagePath = "/purchase-ordering/bundang-order";
let tabs = ["select-products", "confirm-order"];

export default {
    components: {
        ClientPage,
        PageSection,
        AlertDialog,
        TabSelectProducts,
        TabConfirmOrderDefault,
    },
    props: {
        tab: { type: String, default: tabs[0] },
    },
    data: () => ({
        tabs,
        shows: false,
        isDone: false,
        loading: false,
        succeeded: undefined,
        message: "",

        form: initForm(),
    }),
    computed: {
        pageName() {
            return "분당배송";
        },
        targetsAt() {
            return this.form.targetsAt;
        },
        products() {
            return this.form.products;
        },
    },
    mounted() {
        this.setLeftNavMode(LEFT_NAV_MODES.BACK);
        this.setTitle(`${this.pageName}`);
        this.reroute();
    },
    destroyed() {
        this.setLeftNavMode(LEFT_NAV_MODES.MENU);
        this.setTitle();
    },
    watch: {
        tab() {
            switch (this.tab) {
                case "select-products":
                    this.setTitle(`${this.pageName} - 상품선택`);
                    break;
                case "confirm-orders":
                    this.setTitle(`${this.pageName} - 발주내용확인`);
                    break;
                default:
                    this.setTitle(`${this.pageName}`);
                    break;
            }
        },
    },
    methods: {
        ...mapMutations("ui", {
            setTitle: "header/setTitle",
            setLeftNavMode: "header/setLeftNavMode",
        }),
        reroute() {
            let path = `${pagePath}/${this.tab}`;
            if (this.$route.path != path) {
                this.$router.replace({ path });
            }
        },
        backward() {
            this.$router.go(-1);
        },
        forward() {
            let thisTabIndex = tabs.findIndex((item) => item == this.tab);
            let nextTab = tabs[thisTabIndex + 1];
            let path = `${pagePath}/${nextTab}`;
            this.$router.push({ path });
        },
        close() {
            this.shows = false;
            if (this.isDone) {
                let path = this.succeeded ? "/mypage/purchase-orders" : "/";
                this.$router.push({ path });
            }
        },
        async post() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { form } = this;
                form.targetsAt = Date.now().toDate();
                await api.v1.me.purchaseOrders.post(form);
                this.message = "발주가 완료되었습니다.";
                this.succeeded = true;
            } catch (error) {
                this.message = error?.message;
                this.succeeded = false;
            } finally {
                this.loading = false;
                this.isDone = true;
                this.shows = true;
            }
        },
    },
};
</script>
