<template>
    <v-card flat tile>
        <v-card-title v-if="hasTitle"> <slot name="title" /> </v-card-title>
        <v-card-text class="pa-0 mt-n10">
            <v-expand-transition>
                <div v-show="!filter._category">
                    <products-select-search v-model="filter.name" class="py-10" @search="search" />
                </div>
            </v-expand-transition>
            <v-expand-transition>
                <div v-show="!filter.name">
                    <products-select-category v-model="filter._category" v-bind="{ access }" class="py-10" @categories="setCategories" @input="(value) => (value ? search() : null)" />
                </div>
            </v-expand-transition>
            <products-select-list v-bind="{ title: listTitle, products }" @input="(items) => $emit('input', items)" />
        </v-card-text>
    </v-card>
</template>

<script>
import ProductsSelectList from "./products-select-list.vue";
import ProductsSelectSearch from "./products-select-search.vue";
import ProductsSelectCategory from "./products-select-category.vue";
import api from "@/api";

export default {
    components: {
        ProductsSelectList,
        ProductsSelectSearch,
        ProductsSelectCategory,
    },
    props: {
        form: { type: Object, default: () => ({}) },
        hasTitle: { type: Boolean, default: false },
    },
    data: () => ({
        filter: {
            name: null,
            _category: null,
        },
        listTitle: null,
        products: [],
        categories: [],
    }),
    computed: {
        access() {
            let { type: siteFeatures } = this.form;
            let _userGroups = this.$store.state.user?._group;
            return { _userGroups, siteFeatures };
        },
    },
    methods: {
        setCategories(categories) {
            this.categories = categories;
        },
        async search() {
            this.listTitle = this.makeListTitle();
            if (this.filter.name || this.filter._category) {
                let { ...access } = this.access || {};
                if (!access?._userGroups) delete access._userGroups;
                let { products } = await api.v1.shop.products.gets({ params: { ...this.filter, access } });
                this.products = products;
            } else {
                this.products = [];
            }
        },
        makeListTitle() {
            let { name, _category } = this.filter;
            if (name) return `“${name}”`;
            if (_category) {
                let categoryName = this.categories.find(({ _id }) => _id == _category)?.fullName;
                return `${categoryName}`;
            }
            return "검색어가 없습니다";
        },
    },
};
</script>

<style>
</style>