var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "flat": "",
      "tile": ""
    }
  }, [_vm.hasTitle ? _c('v-card-title', [_vm._t("title")], 2) : _vm._e(), _c('v-card-text', {
    staticClass: "pa-0 mt-n10"
  }, [_c('v-expand-transition', [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.filter._category,
      expression: "!filter._category"
    }]
  }, [_c('products-select-search', {
    staticClass: "py-10",
    on: {
      "search": _vm.search
    },
    model: {
      value: _vm.filter.name,
      callback: function callback($$v) {
        _vm.$set(_vm.filter, "name", $$v);
      },
      expression: "filter.name"
    }
  })], 1)]), _c('v-expand-transition', [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.filter.name,
      expression: "!filter.name"
    }]
  }, [_c('products-select-category', _vm._b({
    staticClass: "py-10",
    on: {
      "categories": _vm.setCategories,
      "input": function input(value) {
        return value ? _vm.search() : null;
      }
    },
    model: {
      value: _vm.filter._category,
      callback: function callback($$v) {
        _vm.$set(_vm.filter, "_category", $$v);
      },
      expression: "filter._category"
    }
  }, 'products-select-category', {
    access: _vm.access
  }, false))], 1)]), _c('products-select-list', _vm._b({
    on: {
      "input": function input(items) {
        return _vm.$emit('input', items);
      }
    }
  }, 'products-select-list', {
    title: _vm.listTitle,
    products: _vm.products
  }, false))], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }