var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', {
    attrs: {
      "tile": ""
    }
  }, [_c('h2', {
    staticClass: "tit"
  }, [_vm._v("보내시는 분")]), _c('v-divider', {
    staticClass: "my-20"
  }), !_vm.readonly ? _c('div', {
    staticClass: "mb-20"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "hide-details": "",
      "placeholder": "이름"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.sender.name,
      callback: function callback($$v) {
        _vm.$set(_vm.sender, "name", $$v);
      },
      expression: "sender.name"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "hide-details": "",
      "placeholder": "연락처"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.sender.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.sender, "phone", $$v);
      },
      expression: "sender.phone"
    }
  })], 1)], 1)], 1) : _vm._e(), _c('div', [_vm.readonly ? [_c('text-list', [_c('text-list-item', {
    attrs: {
      "listName": "이름",
      "listText": _vm.sender.name || '-'
    }
  })], 1), _c('text-list', [_c('text-list-item', {
    attrs: {
      "listName": "연락처",
      "listText": _vm.sender.phone || '-'
    }
  })], 1)] : _vm._e(), _c('text-list', [_c('text-list-item', {
    attrs: {
      "listName": "주소",
      "listText": _vm.sender.address || '-'
    }
  })], 1)], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }