<template>
    <v-sheet tile>
        <card-receiver v-model="order.receiver">
            <template #header> <slot name="header" /> </template>
            <text-list-item listName="상품">
                <card-products-result v-model="order.products" hideTitle v-bind="{ readonly }" @input="emit" dense />
            </text-list-item>
        </card-receiver>
    </v-sheet>
</template>

<script>
import { initReceiver } from "@/lib";

import TextListItem from "../../list/text-list-item.vue";
import CardReceiver from "../card-receiver.vue";
import CardProductsResult from "../card-products-result/card-products-result.vue";

export default {
    components: {
        TextListItem,
        CardReceiver,
        CardProductsResult,
    },
    props: {
        value: { type: Object, default: () => ({}) }, // order
        readonly: { type: Boolean, default: false },
    },
    data: () => ({
        order: {
            receiver: initReceiver(),
            products: [],
        },
    }),
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.order = JSON.parse(JSON.stringify(this.value));
        },
        emit() {
            this.$emit("input", this.order);
        },
    },
};
</script>

<style>
</style>