<template>
    <v-row v-bind="$attrs">
        <v-col cols="auto">
            <p class="min-w-44px page-text page-text--lg font-weight-medium grey--text text--darken-4">{{ listName }}</p>
        </v-col>
        <v-col>
            <p class="page-text page-text--lg">{{ listText }}</p>
            <slot />
        </v-col>
    </v-row>
</template>

<script>
export default {
    props: {
        listName: { type: String, default: "" },
        listText: { type: String, default: "" },
    },
    components: {},
    data: () => {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
