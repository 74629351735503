var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', [_c('image-input-btn', _vm._b({
    attrs: {
      "title": "영수증",
      "accept": "image/*",
      "x-large": "",
      "outlined": "",
      "block": "",
      "color": "primary"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.receipt,
      callback: function callback($$v) {
        _vm.receipt = $$v;
      },
      expression: "receipt"
    }
  }, 'image-input-btn', {
    readonly: _vm.readonly
  }, false), [_c('v-icon', {
    staticClass: "mr-10"
  }, [_vm._v("mdi-camera-enhance")])], 1), _c('v-row', {
    staticClass: "mt-6"
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('image-input-btn', _vm._b({
    attrs: {
      "title": "명함",
      "accept": "image/*",
      "x-large": "",
      "outlined": "",
      "block": "",
      "color": "primary"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.businessCard,
      callback: function callback($$v) {
        _vm.businessCard = $$v;
      },
      expression: "businessCard"
    }
  }, 'image-input-btn', {
    readonly: _vm.readonly
  }, false))], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('image-input-btn', _vm._b({
    attrs: {
      "title": "인사장",
      "accept": "image/*",
      "x-large": "",
      "outlined": "",
      "block": "",
      "color": "primary"
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.greetingCard,
      callback: function callback($$v) {
        _vm.greetingCard = $$v;
      },
      expression: "greetingCard"
    }
  }, 'image-input-btn', {
    readonly: _vm.readonly
  }, false))], 1)], 1), _c('v-text-field', _vm._b({
    staticClass: "mt-12",
    attrs: {
      "outlined": "",
      "hide-details": "",
      "placeholder": "메시지를 입력해주세요."
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.message,
      callback: function callback($$v) {
        _vm.message = $$v;
      },
      expression: "message"
    }
  }, 'v-text-field', {
    readonly: _vm.readonly
  }, false))], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }