var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('page-section', {
    staticClass: "page-section--first page-section--sm"
  }, [_c('purchase-order-list-search')], 1), _c('page-section', [_c('purchase-order-list-filter'), _c('border-list', {
    staticClass: "pt-20"
  }, _vm._l(_vm.items, function (item) {
    return _c('purchase-order-list-item', _vm._b({
      key: item._id
    }, 'purchase-order-list-item', {
      item: item
    }, false));
  }), 1), _vm.summary.totalCount && _vm.orders.length < _vm.summary.totalCount ? _c('v-btn', _vm._b({
    staticClass: "mt-20",
    attrs: {
      "block": "",
      "outlined": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.replace({
          query: Object.assign({}, _vm.$route.query, {
            page: _vm.page + 1
          })
        });
      }
    }
  }, 'v-btn', {
    loading: _vm.loading
  }, false), [_vm._v("더 보기")]) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }