<template>
    <v-sheet>
        <page-section class="page-section--first">
            <h2 class="tit tit--lg">{{ title }}</h2>
            <p v-if="!hideTargetsAt" class="page-text page-text--lg font-weight-medium primary--text mt-8">{{ targetsAtText }} : {{ targetsAt }}</p>
        </page-section>

        <page-section> <card-products-select v-bind="{ form }" @input="setProducts" /> </page-section>

        <page-section> <card-products-result v-model="products" /> </page-section>

        <page-section>
            <v-row class="row--small">
                <v-col v-if="!hideBackwardBtn" cols="6">
                    <v-btn block outlined color="primary" class="v-size--xx-large" @click="$emit('backward')">{{ backwardText }}</v-btn>
                </v-col>
                <v-col :cols="hideBackwardBtn ? 12 : 6">
                    <v-btn block color="primary" class="v-size--xx-large" v-bind="{ disabled }" @click="forward">선택 완료</v-btn>
                </v-col>
            </v-row>
        </page-section>
    </v-sheet>
</template>

<script>
import PageSection from "@/components/client/sub/page-section.vue";
import CardProductsSelect from "../card-products-select/card-products-select.vue";
import CardProductsResult from "../card-products-result/card-products-result.vue";

export default {
    components: {
        PageSection,
        CardProductsSelect,
        CardProductsResult,
    },
    props: {
        form: { type: Object, default: () => ({}) },
        value: { type: Array, default: () => [] }, // form.products
        title: { type: String, default: "상품을 선택해 주세요." },
        targetsAt: { type: String },
        backwardText: { type: String, default: "입고일 수정" },
        targetsAtText: { type: String, default: "입고일" },
        hideTargetsAt: { type: Boolean, default: false },
        hideBackwardBtn: { type: Boolean, default: false },
    },
    data: () => ({
        products: [],
    }),
    computed: {
        disabled() {
            return !this.products.length;
        },
    },
    watch: {
        products() {
            this.$emit("input", this.products);
        },
    },
    methods: {
        setProducts(items) {
            let products = JSON.parse(JSON.stringify(this.products));

            for (const item of items) {
                let index = products.findIndex(({ _id }) => _id == item._id);
                if (index >= 0) products[index].quantity += item.quantity;
                else products.push(item);
            }

            this.products = products;
        },
        forward() {
            try {
                if (this.products.length < 1) throw new Error(this.title);

                this.$emit("forward");
            } catch (error) {
                alert(error.message);
                return;
            }
        },
    },
};
</script>

