var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog-default', {
    attrs: {
      "title": "발주취소",
      "contentClass": "align-self-start"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var attrs = _ref.attrs,
            on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs: attrs,
          on: on
        })];
      }
    }], null, true)
  }, [_c('purchase-order-view-info', _vm._b({
    staticClass: "mx-n16 pt-20 pb-0"
  }, 'purchase-order-view-info', {
    order: _vm.order
  }, false)), _c('div', {
    staticClass: "pt-20"
  }, [_c('v-btn', _vm._b({
    staticClass: "v-size--xx-large",
    attrs: {
      "block": "",
      "outlined": "",
      "color": "primary"
    },
    on: {
      "click": _vm.cancelOrder
    }
  }, 'v-btn', {
    loading: _vm.loading
  }, false), [_vm._v("발주취소")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }