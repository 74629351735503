<template>
    <v-dialog v-model="shows" scrollable max-width="500px" content-class="v-dialog--default rounded-0">
        <template v-slot:activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card flat tile>
            <v-card-title>
                <v-spacer />
                <v-btn icon tile class="v-dialog--default__close mr-n12" @click="shows = false"><v-icon size="20">mdi-close</v-icon></v-btn>
            </v-card-title>
            <v-card-text>
                <card-products-select v-model="products" v-bind="{ form }" @input="emit" />
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import CardProductsSelect from "./card-products-select/card-products-select.vue";
export default {
    components: {
        CardProductsSelect,
    },
    props: {
        form: { type: Object, default: () => ({}) },
    },
    data: () => ({
        shows: false,
        products: [],
    }),
    watch: {
        shows() {
            if (!this.shows) this.init();
        },
    },
    methods: {
        init() {
            this.products = [];
        },
        emit() {
            this.$emit("input", this.products);
            this.shows = false;
        },
    },
};
</script>

<style>
</style>