<template>
    <v-tab-item :value="tab">
        <page-section class="page-section--first">
            <h2 class="tit tit--lg">{{ title }}</h2>
            <p class="page-text page-text--lg font-weight-medium primary--text mt-8">발송일 : {{ targetsAt }}</p>
        </page-section>

        <page-section>
            <card-sender v-model="sender" @input="emit" />
        </page-section>

        <page-section>
            <v-row class="row--small">
                <v-col cols="6">
                    <v-btn block outlined color="primary" class="v-size--xx-large" @click="$emit('backward')">발송일 수정</v-btn>
                </v-col>
                <v-col cols="6">
                    <v-btn block color="primary" class="v-size--xx-large" v-bind="{ disabled }" @click="forward">입력 완료</v-btn>
                </v-col>
            </v-row>
        </page-section>
    </v-tab-item>
</template>

<script>
import { digitLengthOf, initSender } from "@/lib";

import CardSender from "./card-sender.vue";
import PageSection from "@/components/client/sub/page-section.vue";

export default {
    components: {
        CardSender,
        PageSection,
    },
    props: {
        tab: { type: String },
        value: { type: Object, default: initSender }, // form.sender
        title: { type: String, default: "발송정보를 입력해 주세요." },
        targetsAt: { type: String },
    },
    data: () => ({
        sender: initSender(),
    }),
    computed: {
        disabled() {
            let { name, phone, address } = this.sender;
            return !name || !phone || !address;
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.sender = JSON.parse(JSON.stringify(this.value));
        },
        emit() {
            this.$emit("input", this.sender);
        },
        forward() {
            try {
                if (!this.sender.name) throw new Error("이름을 입력해주세요");
                if (!this.sender.phone) throw new Error("연락처를 입력해주세요");
                if (![9, 10, 11].includes(digitLengthOf(this.sender.phone))) throw new Error("연락처를 입력해주세요");

                this.$emit("forward");
            } catch (error) {
                alert(error.message);
            }
        },
    },
};
</script>

