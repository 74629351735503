<template>
    <v-dialog-default title="발주취소" contentClass="align-self-start">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>

        <purchase-order-view-info v-bind="{ order }" class="mx-n16 pt-20 pb-0" />

        <div class="pt-20">
            <v-btn block outlined color="primary" class="v-size--xx-large" v-bind="{ loading }" @click="cancelOrder">발주취소</v-btn>
        </div>
    </v-dialog-default>
</template>

<script>
import VDialogDefault from "@/components/plugins/vuetify/v-dialog-default.vue";
import PurchaseOrderViewInfo from "./purchase-order-view-info.vue";
import api from "@/api";
export default {
    components: {
        VDialogDefault,
        PurchaseOrderViewInfo,
    },
    props: {
        order: { type: Object, default: () => ({}) },
    },
    data: () => ({
        loading: false,
    }),
    methods: {
        async cancelOrder() {
            this.loading = true;
            try {
                let { _id } = this.order;
                await api.v1.me.purchaseOrders.cancel({ _id });
                alert("취소되었습니다");
                this.$emit("canceled");
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style>
</style>