<template>
    <v-tab-item :value="tab">
        <page-section class="page-section--first">
            <h2 class="tit tit--lg">발주 내용을 확인해주세요.</h2>
            <p v-if="!hideTargetsAt" class="page-text page-text--lg font-weight-medium primary--text mt-8">입고일 : {{ targetsAt }}</p>
        </page-section>

        <page-section> <card-products-result :value="products" readonly /> </page-section>

        <page-section>
            <v-row class="row--small">
                <v-col cols="6">
                    <v-btn block outlined color="primary" class="v-size--xx-large" @click="$emit('backward')" v-bind="{ loading }">상품 수정</v-btn>
                </v-col>
                <v-col cols="6">
                    <v-btn block color="primary" class="v-size--xx-large" @click="$emit('forward')" v-bind="{ loading }"> 발주 완료 </v-btn>
                </v-col>
            </v-row>
        </page-section>
    </v-tab-item>
</template>

<script>
import PageSection from "@/components/client/sub/page-section.vue";
import CardProductsResult from "./card-products-result/card-products-result.vue";

export default {
    components: {
        PageSection,
        CardProductsResult,
    },
    props: {
        tab: { type: String },
        products: { type: Array, default: () => [] }, // form.products
        targetsAt: { type: String },

        loading: { type: Boolean, default: false },
        hideTargetsAt: { type: Boolean, default: false },
    },
};
</script>

