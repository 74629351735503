<template>
    <v-tab-item :value="tab">
        <page-section class="page-section--first">
            <h2 class="tit tit--lg">{{ title }}</h2>
            <p class="page-text page-text--lg font-weight-medium primary--text mt-8">발송일 : {{ targetsAt }}</p>
        </page-section>
        <template v-if="isBulkOrder">
            <page-section v-for="order in orders" :key="order.tempId">
                <card-receiver v-bind="{ value: order.receiver }" @input="(receiver) => update({ ...order, receiver })">
                    <template #header>
                        <v-row justify="space-between">
                            <v-col cols="auto"> <h2 class="tit">받으시는 분</h2> </v-col>
                            <v-col v-if="isBulkOrder" cols="auto">
                                <v-btn small icon color="#bbb" class="h-auto mr-n4" @click="remove(order)"> <v-icon size="24">mdi-close</v-icon> </v-btn>
                            </v-col>
                        </v-row>
                    </template>
                </card-receiver>
            </page-section>
            <page-section> <v-btn block outlined color="primary" class="v-size--xx-large" @click="addItem">받으시는 분 추가</v-btn> </page-section>
        </template>
        <template v-else>
            <page-section> <card-receiver v-bind="{ value: orders[0]?.receiver }" @input="(receiver) => update({ ...orders[0], receiver })" /> </page-section>
        </template>

        <page-section>
            <v-row class="row--small">
                <v-col cols="6">
                    <v-btn block outlined color="primary" class="v-size--xx-large" @click="$emit('backward')">보내시는 분 수정</v-btn>
                </v-col>
                <v-col cols="6">
                    <v-btn block color="primary" class="v-size--xx-large" v-bind="{ disabled }" @click="forward">입력 완료</v-btn>
                </v-col>
            </v-row>
        </page-section>
    </v-tab-item>
</template>

<script>
import { initReceiver } from "@/lib";

import PageSection from "@/components/client/sub/page-section.vue";
import CardReceiver from "./card-receiver.vue";

export default {
    components: {
        PageSection,
        CardReceiver,
    },
    props: {
        tab: { type: String },
        value: { type: Array, default: () => [] }, // orders
        title: { type: String, default: "발송정보를 입력해 주세요." },
        isBulkOrder: { type: Boolean, default: false },
        targetsAt: { type: String },
    },
    data: () => ({
        orders: [],
    }),
    computed: {
        disabled() {
            return !this.orders.length || this.orders.some(({ receiver: { name, phone, postcode, address1, address2 } }) => !name || !phone || !postcode || !address1 || !address2);
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.orders = JSON.parse(JSON.stringify(this.value));
        },
        emit() {
            this.$emit("input", this.orders);
        },
        update(item) {
            let orders = [...this.orders];
            let index = orders.findIndex(({ tempId }) => tempId == item.tempId);
            if (index >= 0) orders.splice(index, 1, item);
            else orders = [...orders, item];
            this.orders = orders;
            this.$nextTick(this.emit);
        },
        addItem() {
            this.orders.push({
                tempId: Math.random(),
                receiver: initReceiver(),
                products: [],
            });
            this.orders = [...this.orders];
            this.$nextTick(this.emit);
        },
        remove(item) {
            let orders = [...this.orders];
            let index = orders.findIndex(({ tempId }) => tempId == item.tempId);
            if (index >= 0) orders.splice(index, 1);
            this.orders = orders;
            this.$nextTick(this.emit);
        },
        forward() {
            try {
                if (this.disabled) throw new Error(this.title);

                this.$emit("forward");
            } catch (error) {
                alert(error.message);
            }
        },
    },
};
</script>

