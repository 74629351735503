<template>
    <v-tab-item :value="tab">
        <page-section class="page-section--first">
            <h2 class="tit tit--lg">{{ title }}</h2>
        </page-section>

        <page-section>
            <div>
                <v-text-field v-model="date" append-icon="event" outlined hide-details readonly placeholder="0000-00-00" />
                <v-card flat color="grey lighten-5" class="py-10 mt-12">
                    <v-date-picker v-model="date" type="date" no-title scrollable color="secondary" locale="en-US" v-bind="{ allowedDates, weekdayFormat, monthFormat, headerDateFormat, titleDateFormat }" />
                </v-card>
            </div>
            <div class="mt-20">
                <v-row class="row--small">
                    <v-col cols="6" v-if="hasType">
                        <v-btn block outlined color="primary" class="v-size--xx-large" @click="$emit('backward')">유형 변경</v-btn>
                    </v-col>
                    <v-col :cols="hasType ? 6 : 12">
                        <v-btn block color="primary" class="v-size--xx-large" v-bind="{ disabled }" @click="forward">선택 완료</v-btn>
                    </v-col>
                </v-row>
            </div>
        </page-section>
    </v-tab-item>
</template>

<script>
import PageSection from "@/components/client/sub/page-section.vue";

export default {
    components: {
        PageSection,
    },
    props: {
        value: { type: String }, // form.targetsAt
        tab: { type: String },
        title: { type: String, default: "입고일을 선택해 주세요." },
        hasType: { type: Boolean, default: false },
        allowedDates: { type: Function, default: () => () => true },
    },
    data: () => ({
        date: null,
    }),
    computed: {
        disabled() {
            return !this.date;
        },
    },
    mounted() {
        this.date = this.value;
    },
    watch: {
        date() {
            this.$emit("input", this.date);
        },
    },
    methods: {
        weekdayFormat(date) {
            const daysOfWeek = ["일", "월", "화", "수", "목", "금", "토"];
            let i = new Date(date).getDay(date);
            return daysOfWeek[i];
        },
        monthFormat(date) {
            const monthName = ["1월", "2월", "3월", "4월", "5월", "6월", "7월", "8월", "9월", "10월", "11월", "12월"];
            let i = new Date(date).getMonth(date);
            return monthName[i];
        },
        titleDateFormat(date) {
            return this.monthFormat(date);
        },
        headerDateFormat(date) {
            const monthName = ["1월", "2월", "3월", "4월", "5월", "6월", "7월", "8월", "9월", "10월", "11월", "12월"];
            let i = new Date(date).getMonth(date);
            const year = new Date(date).getFullYear(date);
            const month = monthName[i];
            return `${year}년 ${month}`;
        },
        forward() {
            try {
                if (!this.date) throw new Error(this.title);

                this.$emit("forward");
            } catch (error) {
                alert(error.message);
                return;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.v-picker,
::v-deep .theme--light.v-picker__body {
    background-color: transparent;
}
.v-picker {
    width: 100%;
}
::v-deep .v-date-picker-table--date .v-btn {
    height: 40px;
    width: 40px;
    border-radius: 100% !important;
}
::v-deep .v-date-picker-table .v-btn.v-btn--active.secondary {
    color: #fff;
}
</style>
