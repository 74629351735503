<template>
    <v-tab-item :value="tab">
        <page-section class="page-section--first">
            <h2 class="tit tit--lg">발주 내용을 확인해주세요.</h2>
            <p class="page-text page-text--lg font-weight-medium primary--text mt-8">입고일 : {{ form?.targetsAt }}</p>
        </page-section>

        <page-section> <card-sender :value="form.sender" readonly /> </page-section>
        <page-section>
            <h2 class="tit">첨부파일</h2>
            <v-divider class="my-20" />
            <card-files :value="form" readonly />
        </page-section>

        <page-section v-for="order in orders" :key="order.tempId">
            <card-orders-result :value="order" readonly />
        </page-section>

        <page-section>
            <v-row class="row--small">
                <v-col cols="6">
                    <v-btn block outlined color="primary" class="v-size--xx-large" @click="$emit('backward')" v-bind="{ loading }">{{ backwardText }}</v-btn>
                </v-col>
                <v-col cols="6">
                    <v-btn block color="primary" class="v-size--xx-large" @click="$emit('forward')" v-bind="{ loading }"> 발주 완료 </v-btn>
                </v-col>
            </v-row>
        </page-section>
    </v-tab-item>
</template>

<script>
import CardFiles from "./card-files.vue";
import CardSender from "./card-sender.vue";
import PageSection from "@/components/client/sub/page-section.vue";
import CardOrdersResult from "./card-orders-result/card-orders-result.vue";

export default {
    components: {
        CardFiles,
        CardSender,
        PageSection,
        CardOrdersResult,
    },
    props: {
        tab: { type: String },
        form: { type: Object, default: () => ({}) },
        orders: { type: Array, default: () => [] },
        backwardText: { type: String, default: "첨부파일 수정" },

        loading: { type: Boolean, default: false },
    },
};
</script>

