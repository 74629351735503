<template>
    <border-list-item>
        <v-row align="center">
            <v-col>
                <p class="page-text font-weight-bold grey--text text--darken-4">{{ item.name }}</p>
            </v-col>
            <v-col cols="auto">
                <v-row no-gutters class="input-number" align="center">
                    <v-col cols="auto">
                        <v-btn x-small icon outlined color="grey lighten-3" @click="decrease"> <v-icon size="18" color="grey"> mdi-minus </v-icon> </v-btn>
                    </v-col>
                    <v-col> <v-text-field v-model="item.quantity" type="number" min="0" dense outlined hide-details class="input-number__num" @input="emit" /> </v-col>
                    <v-col cols="auto">
                        <v-btn x-small icon outlined color="grey lighten-3" @click="increase"><v-icon size="18" color="grey">mdi-plus</v-icon></v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </border-list-item>
</template>

<script>
import BorderListItem from "../../list/border-list-item.vue";

export default {
    components: {
        BorderListItem,
    },
    props: {
        value: { type: Object, default: () => ({}) },
    },
    data: () => ({
        item: {},
    }),
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.item = JSON.parse(JSON.stringify(this.value));
        },
        emit() {
            this.$emit("input", this.item);
        },
        decrease() {
            let quantity = this.item.quantity - 1;
            if (quantity < 0) quantity = 0;
            this.item.quantity = quantity;
            this.$nextTick(this.emit);
        },
        increase() {
            this.item.quantity += 1;
            this.$nextTick(this.emit);
        },
    },
};
</script>

<style>
</style>