<template>
    <v-tab-item :value="tab">
        <page-section class="page-section--first">
            <v-row>
                <v-col cols="6" v-for="{ text, value } in items" :key="text">
                    <v-btn block height="64" color="secondary" class="px-22" @click="$emit('input', value)">
                        <div class="d-flex align-center justify-space-between w-100">
                            <strong class="font-size-20">{{ text }}</strong>
                            <v-icon size="26" class="mr-n8">mdi-chevron-right</v-icon>
                        </div>
                    </v-btn>
                </v-col>
            </v-row>
        </page-section>
    </v-tab-item>
</template>

<script>
import { PURCHASE_ORDER_TYPES } from "@/assets/variables";

import PageSection from "@/components/client/sub/page-section.vue";

let { STORE_ORDER_BY_COMPANY, STORE_ORDER_BY_COURIER, STORE_ORDER_BY_EXPRESS } = PURCHASE_ORDER_TYPES;

let items = [
    { text: "물류입고", value: STORE_ORDER_BY_COMPANY.value },
    { text: "택배입고", value: STORE_ORDER_BY_COURIER.value },
    { text: "직배송", value: STORE_ORDER_BY_EXPRESS.value },
];

export default {
    components: {
        PageSection,
    },
    props: {
        tab: { type: String },
    },
    data: () => ({
        items,
    }),
};
</script>

