<template>
    <page-section class="page-section--sm">
        <v-row class="row--small">
            <v-col cols="6">
                <actions-cancel v-if="isCancelable" v-bind="{ order }" @canceled="$emit('search')">
                    <template #activator="{ attrs, on }">
                        <v-btn block outlined color="grey lighten-3" class="v-size--xx-large" v-bind="attrs" v-on="on">발주 취소</v-btn>
                    </template>
                </actions-cancel>
                <template v-else-if="isCanceled">
                    <v-btn block disabled class="v-size--xx-large">취소 완료</v-btn>
                </template>
                <!-- <actions-claim v-else-if="isClaimable" v-bind="{ order }" @posted="$emit('search')">
                    <template #activator="{ attrs, on, loading }">
                        <v-btn v-bind="{ ...attrs, loading }" v-on="on" block outlined color="secondary" class="v-size--xx-large">클레임</v-btn>
                    </template>
                </actions-claim> -->
                <template v-else-if="isClaimReceived">
                    <v-btn block disabled class="v-size--xx-large">클레임</v-btn>
                </template>
                <template v-else>
                    <v-btn block disabled class="v-size--xx-large">발주 완료</v-btn>
                </template>
            </v-col>
            <v-col cols="6">
                <v-btn block outlined color="primary" class="v-size--xx-large" @click="$router.go(-1)">리스트</v-btn>
            </v-col>
        </v-row>
    </page-section>
</template>

<script>
import dayjs from "dayjs";
import { PURCHASE_ORDER_STATES } from "@/assets/variables";

import PageSection from "@/components/client/sub/page-section.vue";
import ActionsClaim from "./actions-claim.vue";
import ActionsCancel from "./actions-cancel.vue";

export default {
    components: {
        PageSection,
        ActionsClaim,
        ActionsCancel,
    },
    props: {
        order: { type: Object, default: () => ({}) },
    },
    computed: {
        isCancelable() {
            // return false;
            // return true;
            let { ORDERED } = PURCHASE_ORDER_STATES;
            let stateOkay = [ORDERED].map(({ value }) => value).includes(this.order.state);

            let cutout = dayjs(this.order.createdAt).hour(15).minute(0).millisecond(0);
            let cutoutOkay = dayjs().isBefore(cutout);
            return stateOkay && cutoutOkay;
        },
        isCanceled() {
            let { CANCELED } = PURCHASE_ORDER_STATES;
            return [CANCELED].map(({ value }) => value).includes(this.order.state);
        },
        // isClaimable() {
        //     // return false;
        //     // return true;
        //     let { ORDERED, PROCESSED } = PURCHASE_ORDER_STATES;
        //     return [ORDERED, PROCESSED].map(({ value }) => value).includes(this.order.state);
        // },
        isClaimReceived() {
            let { CLAIM_RECEIVED } = PURCHASE_ORDER_STATES;
            return [CLAIM_RECEIVED].map(({ value }) => value).includes(this.order.state);
        },
    },
};
</script>

<style>
</style>