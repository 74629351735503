<template>
    <page-section v-if="order.sender">
        <h2 class="tit">보내시는 분</h2>
        <v-divider class="my-20" />
        <table class="table-default">
            <tr v-for="item in items" :key="item.text">
                <th :class="item.className">{{ item.text }}</th>
                <td>{{ item.value }}</td>
            </tr>
        </table>
    </page-section>
</template>

<script>
import PageSection from "@/components/client/sub/page-section.vue";

export default {
    components: {
        PageSection,
    },
    props: {
        order: { type: Object, default: () => ({}) },
    },
    computed: {
        items() {
            let { name, phone, address } = this.order?.sender || {};
            return [
                { text: "이름", value: name || "-" },
                { text: "연락처", value: phone || "-" },
                { text: "주소", value: address || "-" },
            ];
        },
    },
};
</script>

<style>
</style>