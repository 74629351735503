var render = function () {
  var _vm$title;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h3', {
    staticClass: "tit py-20"
  }, [_vm._v(_vm._s((_vm$title = _vm.title) !== null && _vm$title !== void 0 ? _vm$title : "상품을 검색해주세요"))]), _c('border-list', {
    staticClass: "border-list--grey py-10",
    attrs: {
      "no-gutters": ""
    }
  }, [_vm._l(_vm.items, function (item, index) {
    return _c('products-select-list-item', {
      key: item._id,
      attrs: {
        "value": item
      },
      on: {
        "input": function input(item) {
          return _vm.items.splice(index, 1, item);
        }
      }
    });
  }), _vm.title && _vm.items.length == 0 ? _c('border-list-item', [_vm._v(" 검색결과가 없습니다 ")]) : _vm._e()], 2), _c('v-btn', _vm._b({
    staticClass: "v-size--xx-large",
    attrs: {
      "outlined": "",
      "block": "",
      "color": "primary"
    },
    on: {
      "click": _vm.select
    }
  }, 'v-btn', {
    disabled: _vm.disabled
  }, false), [_vm._v("상품담기")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }