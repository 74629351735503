<template>
    <v-sheet>
        <image-input-btn v-model="receipt" title="영수증" accept="image/*" x-large outlined block color="primary" v-bind="{ readonly }" @input="emit"> <v-icon class="mr-10">mdi-camera-enhance</v-icon> </image-input-btn>
        <v-row class="mt-6">
            <v-col cols="6"><image-input-btn v-model="businessCard" title="명함" accept="image/*" x-large outlined block color="primary" v-bind="{ readonly }" @input="emit" /> </v-col>
            <v-col cols="6"><image-input-btn v-model="greetingCard" title="인사장" accept="image/*" x-large outlined block color="primary" v-bind="{ readonly }" @input="emit" /> </v-col>
        </v-row>
        <v-text-field v-model="message" outlined hide-details placeholder="메시지를 입력해주세요." class="mt-12" v-bind="{ readonly }" @input="emit" />
    </v-sheet>
</template>

<script>
import ImageInputBtn from "@/components/console/dumb/image-input-btn.vue";

export default {
    components: {
        ImageInputBtn,
    },
    props: {
        value: { type: Object, default: () => ({}) }, // form
        readonly: { type: Boolean, default: false },
    },
    data: () => ({
        message: null,

        // 파일
        receipt: null,
        businessCard: null,
        greetingCard: null,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            let { message, receipt, businessCard, greetingCard } = this.value;
            this.message = message || null;
            this.receipt = receipt || null;
            this.businessCard = businessCard || null;
            this.greetingCard = greetingCard || null;
        },
        emit() {
            let { message, receipt, businessCard, greetingCard } = this.$data;

            this.$emit("input", {
                ...this.value,
                message,
                receipt,
                businessCard,
                greetingCard,
            });
        },
    },
};
</script>

