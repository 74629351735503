var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog-default', {
    ref: "dialog",
    attrs: {
      "title": "클레임",
      "contentClass": "align-self-start",
      "persistent": ""
    },
    on: {
      "closed": _vm.reset
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var attrs = _ref.attrs,
            on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs: attrs,
          on: on,
          loading: _vm.loading
        })];
      }
    }], null, true)
  }, [_c('div', [_c('v-select', {
    staticClass: "mb-20",
    attrs: {
      "placeholder": "상품",
      "outlined": "",
      "hide-details": "",
      "items": _vm.order.products || [],
      "item-text": "name",
      "item-value": "_id"
    },
    model: {
      value: _vm.form._product,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "_product", $$v);
      },
      expression: "form._product"
    }
  }), _c('v-textarea', {
    attrs: {
      "placeholder": "내용을 입력하세요.",
      "outlined": "",
      "hide-details": "",
      "no-resize": "",
      "rows": "6"
    },
    model: {
      value: _vm.form.query,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "query", $$v);
      },
      expression: "form.query"
    }
  })], 1), _c('div', {
    staticClass: "pt-20"
  }, [_c('v-btn', _vm._b({
    staticClass: "v-size--xx-large",
    attrs: {
      "block": "",
      "outlined": "",
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, 'v-btn', {
    loading: _vm.loading
  }, false), [_vm._v("클레임")])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }