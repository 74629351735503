<template>
    <v-row class="row--small">
        <v-col cols="6"> <v-select placeholder="대분류" v-model="category0" :items="categories0" item-text="name" item-value="_id" outlined hide-details clearable /> </v-col>
        <v-col cols="6"> <v-select placeholder="중분류" v-model="category1" :items="filteredSubCategories" item-text="name" item-value="_id" :disabled="!category0" outlined hide-details clearable /> </v-col>
    </v-row>
</template>

<script>
import api from "@/api";
export default {
    props: {
        value: { type: String, default: null }, // _category
        access: { type: Object, default: () => ({}) },
    },
    data: () => ({
        category0: null,
        category1: null,
        categories0: [],
        categories1: [],
    }),
    computed: {
        filteredSubCategories() {
            return this.categories1.filter(({ _parent }) => _parent == this.category0);
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        value() {
            this.sync();
        },
        access() {
            this.init();
        },
        category1() {
            this.emit();
        },
        categories0() {
            if (this.value) this.sync();
        },
    },
    methods: {
        sync() {
            if (this.value) {
                this.category1 = this.value || this.category1 || null;
                this.category0 = this.categories0.find(({ _children }) => _children.includes(this.value))?._id || this.category0 || null;
            } else {
                this.category0 = this.category0 || null;
                this.category1 = null;
            }
        },
        emit() {
            this.$emit("input", this.category1);
        },
        async init() {
            let { ...access } = this.access || {};
            let params = { access };
            if (!access?._userGroups) delete params.access._userGroups;
            if (!access?.siteFeatures) delete params.access.siteFeatures;

            const { categories: categories0 } = await api.v1.shop.categories.gets({
                params: { ...params, depth: 0 },
            });
            const { categories: categories1 } = await api.v1.shop.categories.gets({
                params: { ...params, depth: 1 },
            });
            this.categories0 = categories0;
            this.categories1 = categories1;

            this.$emit("categories", categories1);
        },
    },
};
</script>

<style>
</style>