var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "flat": "",
      "tile": ""
    }
  }, [_c('v-card-text', {
    staticClass: "pa-0"
  }, [!_vm.hideTitle ? _c('h3', {
    staticClass: "tit py-20 mt-n10"
  }, [_vm._v("선택한 상품")]) : _vm._e(), _c('border-list', {
    class: {
      'border-list--grey': true,
      'py-10': !_vm.dense
    },
    attrs: {
      "no-gutters": ""
    }
  }, [_vm._l(_vm.items, function (item) {
    return _c('products-result-item', _vm._b({
      key: item._id,
      on: {
        "input": _vm.update,
        "remove": function remove($event) {
          return _vm.remove(item);
        }
      }
    }, 'products-result-item', {
      value: item,
      dense: _vm.dense,
      readonly: _vm.readonly
    }, false));
  }), !_vm.items.length ? _c('v-col', [_vm._v(" 선택한 상품이 없습니다 ")]) : _vm._e()], 2)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }