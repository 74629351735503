var render = function () {
  var _vm$orders$;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-tab-item', {
    attrs: {
      "value": _vm.tab
    }
  }, [_c('page-section', {
    staticClass: "page-section--first"
  }, [_c('h2', {
    staticClass: "tit tit--lg"
  }, [_vm._v(_vm._s(_vm.title))]), _c('p', {
    staticClass: "page-text page-text--lg font-weight-medium primary--text mt-8"
  }, [_vm._v("발송일 : " + _vm._s(_vm.targetsAt))])]), _vm.isBulkOrder ? [_vm._l(_vm.orders, function (order) {
    return _c('page-section', {
      key: order.tempId
    }, [_c('card-receiver', _vm._b({
      on: {
        "input": function input(receiver) {
          return _vm.update(Object.assign({}, order, {
            receiver: receiver
          }));
        }
      },
      scopedSlots: _vm._u([{
        key: "header",
        fn: function fn() {
          return [_c('v-row', {
            attrs: {
              "justify": "space-between"
            }
          }, [_c('v-col', {
            attrs: {
              "cols": "auto"
            }
          }, [_c('h2', {
            staticClass: "tit"
          }, [_vm._v("받으시는 분")])]), _vm.isBulkOrder ? _c('v-col', {
            attrs: {
              "cols": "auto"
            }
          }, [_c('v-btn', {
            staticClass: "h-auto mr-n4",
            attrs: {
              "small": "",
              "icon": "",
              "color": "#bbb"
            },
            on: {
              "click": function click($event) {
                return _vm.remove(order);
              }
            }
          }, [_c('v-icon', {
            attrs: {
              "size": "24"
            }
          }, [_vm._v("mdi-close")])], 1)], 1) : _vm._e()], 1)];
        },
        proxy: true
      }], null, true)
    }, 'card-receiver', {
      value: order.receiver
    }, false))], 1);
  }), _c('page-section', [_c('v-btn', {
    staticClass: "v-size--xx-large",
    attrs: {
      "block": "",
      "outlined": "",
      "color": "primary"
    },
    on: {
      "click": _vm.addItem
    }
  }, [_vm._v("받으시는 분 추가")])], 1)] : [_c('page-section', [_c('card-receiver', _vm._b({
    on: {
      "input": function input(receiver) {
        return _vm.update(Object.assign({}, _vm.orders[0], {
          receiver: receiver
        }));
      }
    }
  }, 'card-receiver', {
    value: (_vm$orders$ = _vm.orders[0]) === null || _vm$orders$ === void 0 ? void 0 : _vm$orders$.receiver
  }, false))], 1)], _c('page-section', [_c('v-row', {
    staticClass: "row--small"
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-btn', {
    staticClass: "v-size--xx-large",
    attrs: {
      "block": "",
      "outlined": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('backward');
      }
    }
  }, [_vm._v("보내시는 분 수정")])], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-size--xx-large",
    attrs: {
      "block": "",
      "color": "primary"
    },
    on: {
      "click": _vm.forward
    }
  }, 'v-btn', {
    disabled: _vm.disabled
  }, false), [_vm._v("입력 완료")])], 1)], 1)], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }