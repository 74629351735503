var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', {
    attrs: {
      "tile": ""
    }
  }, [_c('card-receiver', {
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_vm._t("header")];
      },
      proxy: true
    }], null, true),
    model: {
      value: _vm.order.receiver,
      callback: function callback($$v) {
        _vm.$set(_vm.order, "receiver", $$v);
      },
      expression: "order.receiver"
    }
  }, [_c('text-list-item', {
    attrs: {
      "listName": "상품"
    }
  }, [_c('card-products-result', _vm._b({
    attrs: {
      "hideTitle": "",
      "dense": ""
    },
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.order.products,
      callback: function callback($$v) {
        _vm.$set(_vm.order, "products", $$v);
      },
      expression: "order.products"
    }
  }, 'card-products-result', {
    readonly: _vm.readonly
  }, false))], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }