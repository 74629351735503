<template>
    <v-sheet tile>
        <slot v-if="$slots['header']" name="header" />
        <h2 v-else class="tit">받으시는 분</h2>
        <v-divider class="my-20" />
        <text-list v-if="$slots['default']">
            <text-list-item v-for="item in listItems" :key="item.listName" v-bind="item" />
            <slot />
        </text-list>
        <div v-else>
            <v-row>
                <v-col cols="12"> <v-text-field v-model="receiver.name" outlined hide-details placeholder="이름" @input="emit" /> </v-col>
                <v-col cols="12"> <v-text-field v-model="receiver.phone" outlined hide-details placeholder="연락처" @input="emit" /> </v-col>
                <v-col cols="12" class="pa-0">
                    <v-address-field v-model="receiver" outlined hide-details className="pa-6" :btnAttrs="{ xLarge: true, color: 'grey', className: 'w-100px' }" @input="emit" />
                </v-col>
            </v-row>
        </div>
    </v-sheet>
</template>

<script>
import { initReceiver } from "@/lib";

import TextList from "../list/text-list.vue";
import TextListItem from "../list/text-list-item.vue";
import VAddressField from "@/components/dumb/v-address-field.vue";

export default {
    components: {
        TextList,
        TextListItem,
        VAddressField,
    },
    props: {
        value: { type: Object, default: () => ({}) }, // order.receiver
    },
    data: () => ({
        receiver: initReceiver(),
    }),
    computed: {
        listItems() {
            let { name, phone, postcode, address1, address2 } = this.value;
            let address = "";
            if (postcode) address += `[${postcode}] `;
            if (address1) address += address1 + " ";
            if (address2) address += address2;
            address = address.trim();
            return [
                { listName: "이름", listText: name },
                { listName: "연락처", listText: phone || "-" },
                { listName: "주소", listText: address },
            ];
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.receiver = JSON.parse(JSON.stringify(this.value));
        },
        emit() {
            this.$emit("input", this.receiver);
        },
    },
};
</script>

<style>
</style>