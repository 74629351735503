<template>
    <v-dialog v-model="dialog" scrollable transition="dialog-top-transition" max-width="500px" :content-class="'v-dialog--default ' + contentClass" v-bind="$attrs">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>

        <v-card flat tile>
            <v-card-title>
                {{ title }}<v-btn icon class="v-dialog--default__close" @click="dialog = false"><v-icon size="20">mdi-close</v-icon></v-btn>
            </v-card-title>
            <v-card-text>
                <slot />
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        title: { type: String, default: "" },
        contentClass: { type: String, default: "" },
    },
    data: () => {
        return {
            dialog: false,
        };
    },
    watch: {
        dialog() {
            if (this.dialog) this.$emit("opened");
            else this.$emit("closed");
        },
    },
    methods: {
        close() {
            this.dialog = false;
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep .v-dialog--default {
    max-height: 100vh !important;
    border-radius: 0;
    margin: 0;
    box-shadow: none;
    > .v-card {
        > .v-card__title {
            font-size: 2rem;
            font-weight: 700;
            padding: 20px 16px;
            & + .v-card__text {
                padding-top: 0;
            }
        }
        > .v-card__text {
            color: #111;
            padding: 20px 16px;
        }
    }
    &__close {
        width: 58px !important;
        height: 58px !important;
        margin: -20px -16px -20px auto;
    }
}
</style>
