var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.order.receiver ? _c('page-section', [_c('h2', {
    staticClass: "tit"
  }, [_vm._v("받으시는 분")]), _c('v-divider', {
    staticClass: "my-20"
  }), _c('table', {
    staticClass: "table-default"
  }, _vm._l(_vm.items, function (item) {
    return _c('tr', {
      key: item.text
    }, [_c('th', {
      class: item.className
    }, [_vm._v(_vm._s(item.text))]), _c('td', [_vm._v(_vm._s(item.value))])]);
  }), 0)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }