var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-tab-item', {
    attrs: {
      "value": _vm.tab
    }
  }, [_c('page-section', {
    staticClass: "page-section--first"
  }, [_c('h2', {
    staticClass: "tit tit--lg"
  }, [_vm._v("파일을 첨부해 주세요.")]), _c('p', {
    staticClass: "page-text page-text--lg font-weight-medium primary--text mt-8"
  }, [_vm._v(_vm._s(_vm.targetsAtText) + " : " + _vm._s(_vm.targetsAt))])]), _c('page-section', [_c('card-files', _vm._g(_vm._b({}, 'card-files', {
    value: _vm.value
  }, false), {
    input: _vm.input
  }))], 1), _c('page-section', [_c('v-row', {
    staticClass: "row--small"
  }, [_c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-btn', {
    staticClass: "v-size--xx-large",
    attrs: {
      "block": "",
      "outlined": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('backward');
      }
    }
  }, [_vm._v("상품 수정")])], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('v-btn', {
    staticClass: "v-size--xx-large",
    attrs: {
      "block": "",
      "color": "primary"
    },
    on: {
      "click": _vm.forward
    }
  }, [_vm._v(" 첨부 완료 ")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }