<template>
    <border-list-item :to="`/mypage/purchase-orders/${item._id}`">
        <div class="d-flex align-center justify-space-between">
            <div style="width: calc(100% - 88px)">
                <p class="page-text text-truncate">{{ item.typeTextShorten }}</p>
                <p class="page-text text-truncate">{{ item.number }}</p>
                <p class="page-text text-truncate" v-if="showsTargetsAt">{{ item.targetsAt?.toDate?.() }}</p>
                <p class="page-text text-truncate">{{ item?.receiver?.name }}</p>
            </div>
            <v-chip outlined class="w-64px v-btn--none-active-bg">
                <span :class="stateColor">{{ item.stateText }}</span>
            </v-chip>
            <v-icon size="24" class="mr-n8">mdi-chevron-right</v-icon>
        </div>
    </border-list-item>
</template>

<script>
import { PURCHASE_ORDER_STATES, PURCHASE_ORDER_TYPES } from "@/assets/variables";

import BorderListItem from "../../list/border-list-item.vue";

export default {
    components: {
        BorderListItem,
    },
    props: {
        item: { type: Object, default: () => ({}) },
    },
    computed: {
        stateColor() {
            switch (this.item.state) {
                case PURCHASE_ORDER_STATES.PROCESSED.value:
                    return "primary--text";
                case PURCHASE_ORDER_STATES.CLAIM_RECEIVED.value:
                    return "secondary--text";
                default:
                    return "grey--text text--lighten-1";
            }
        },
        showsTargetsAt() {
            let { BUNDANG_HANDLING_ORDER, STORE_ORDER_BY_COURIER, STORE_ORDER_BY_EXPRESS } = PURCHASE_ORDER_TYPES;

            return !this.isOrderType(BUNDANG_HANDLING_ORDER, STORE_ORDER_BY_COURIER, STORE_ORDER_BY_EXPRESS);
        },
    },
    methods: {
        isOrderType(...items) {
            return items.map(({ value }) => value).includes(this.item.type);
        },
    },
};
</script>

<style>
</style>