<template>
    <v-dialog v-bind="{ value }" max-width="80vmin" width="420" persistent>
        <v-card>
            <v-card-text class="pa-20" style="white-space: pre-line"> {{ message }} </v-card-text>
            <v-divider />
            <v-card-actions class="pa-0">
                <v-btn plain text block @click="$emit('close')">확인</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        value: { type: Boolean, default: false },
        message: { type: String },
    },
};
</script>

<style>
</style>