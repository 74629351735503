<template>
    <client-page>
        <v-tabs-items :value="tab">
            <tab-select-type-store
                v-model="form.type"
                :tab="tabs[0]"
                v-on="{
                    input: forward,
                }"
            />
            <tab-select-targets-at
                v-model="form.targetsAt"
                :tab="tabs[1]"
                hasType
                v-bind="{ allowedDates }"
                v-on="{
                    backward,
                    forward,
                }"
            />
            <tab-select-products
                :value="[form]"
                :tab="tabs[2]"
                :backwardText="isCompanyOrder ? undefined : '유형 변경'"
                v-bind="{ form, targetsAt, hideTargetsAt }"
                v-on="{
                    input: (orders) => (form = orders[0]),
                    backward,
                    forward,
                }"
            />
            <tab-confirm-order-default
                :tab="tabs[3]"
                v-bind="{ targetsAt, products, hideTargetsAt, loading }"
                v-on="{
                    backward,
                    forward: post,
                }"
            />
        </v-tabs-items>
        <alert-dialog v-model="shows" v-bind="{ message }" v-on="{ close }" />
    </client-page>
</template>

<script>
import api from "@/api";
import dayjs from "dayjs";
import { mapMutations } from "vuex";
import { PURCHASE_ORDER_TYPES } from "@/assets/variables";
import { LEFT_NAV_MODES } from "@/store/ui-header";

import ClientPage from "@/pages/client/templates/ClientPage.vue";
import PageSection from "@/components/client/sub/page-section.vue";
import AlertDialog from "@/components/client/dumb/alert-dialog.vue";
import TabSelectProducts from "@/components/client/purchase-ordering/tab-select-products/tab-select-products.vue";
import TabSelectTargetsAt from "@/components/client/purchase-ordering/tab-select-targets-at.vue";
import TabSelectTypeStore from "@/components/client/purchase-ordering/tab-select-type-store.vue";
import TabConfirmOrderDefault from "@/components/client/purchase-ordering/tab-confirm-order-default.vue";

let initForm = () => ({
    type: undefined,
    targetsAt: null,
    products: [],
});
let pagePath = "/purchase-ordering/store-order";
let tabs = ["select-type", "select-targets-at", "select-products", "confirm-orders"];

export default {
    components: {
        ClientPage,
        PageSection,
        AlertDialog,
        TabSelectProducts,
        TabSelectTargetsAt,
        TabSelectTypeStore,
        TabConfirmOrderDefault,
    },
    props: {
        tab: { type: String, default: tabs[0] },
    },
    data: () => ({
        tabs,
        shows: true,
        isDone: false,
        loading: false,
        succeeded: undefined,
        message: "발주 가능시간은 18시 ~ 명일 15시입니다.\n위 발주 가능시간 이내에 발주를 완료해주시기 바랍니다.",

        form: initForm(),
    }),
    computed: {
        isCompanyOrder() {
            return this.form.type == PURCHASE_ORDER_TYPES.STORE_ORDER_BY_COMPANY.value;
        },
        hideTargetsAt() {
            return !this.isCompanyOrder;
        },
        pageName() {
            let pageName = "특정발주";

            let { STORE_ORDER_BY_COMPANY, STORE_ORDER_BY_COURIER, STORE_ORDER_BY_EXPRESS } = PURCHASE_ORDER_TYPES;

            switch (this.form.type) {
                case STORE_ORDER_BY_COMPANY.value: {
                    pageName += "(물류)";
                    break;
                }
                case STORE_ORDER_BY_COURIER.value: {
                    pageName += "(택배)";
                    break;
                }
                case STORE_ORDER_BY_EXPRESS.value: {
                    pageName += "(직배)";
                    break;
                }
            }

            return pageName;
        },
        targetsAt() {
            return this.form.targetsAt;
        },
        products() {
            return this.form.products;
        },
    },
    mounted() {
        this.setLeftNavMode(LEFT_NAV_MODES.BACK);
        this.setTitle(`${this.pageName} - 접수유형선택`);
        this.reroute();
    },
    destroyed() {
        this.setLeftNavMode(LEFT_NAV_MODES.MENU);
        this.setTitle();
    },
    watch: {
        tab() {
            switch (this.tab) {
                case "select-type":
                    this.setTitle(`${this.pageName} - 접수유형선택`);
                    break;
                case "select-targets-at":
                    this.setTitle(`${this.pageName} - 입고일선택`);
                    break;
                case "input-sender":
                case "input-receivers":
                    this.setTitle(`${this.pageName} - 발송정보입력`);
                    break;
                case "select-products":
                    this.setTitle(`${this.pageName} - 상품선택`);
                    break;
                case "attach-files":
                    this.setTitle(`${this.pageName} - 파일첨부`);
                    break;
                case "confirm-orders":
                    this.setTitle(`${this.pageName} - 발주내용확인`);
                    break;
                default:
                    this.setTitle(`${this.pageName}`);
                    break;
            }
        },
    },
    methods: {
        ...mapMutations("ui", {
            setTitle: "header/setTitle",
            setLeftNavMode: "header/setLeftNavMode",
        }),
        reroute() {
            let path = `${pagePath}/${this.tab}`;
            if (this.$route.path != path) {
                this.$router.replace({ path });
            }
        },
        backward() {
            this.$router.go(-1);
        },
        forward() {
            let path;
            switch (this.tab) {
                case tabs[0]: {
                    if (this.isCompanyOrder) path = `${pagePath}/${tabs[1]}`;
                    else path = `${pagePath}/${tabs[2]}`;
                    break;
                }
                default: {
                    let thisTabIndex = tabs.findIndex((item) => item == this.tab);
                    let nextTab = tabs[thisTabIndex + 1];
                    path = `${pagePath}/${nextTab}`;
                }
            }
            this.$router.push({ path });
        },
        close() {
            this.shows = false;
            if (this.isDone) {
                let path = this.succeeded ? "/mypage/purchase-orders" : "/";
                this.$router.push({ path });
            }
        },
        async post() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { form } = this;
                if (!this.isCompanyOrder) delete form.targetsAt;

                await api.v1.me.purchaseOrders.post(form);

                this.message = "발주가 완료되었습니다.";
                this.succeeded = true;
            } catch (error) {
                this.message = "발주 가능시간이 아닙니다.\n18시 ~ 명일 15시에 다시\n발주해 주시기 바랍니다.";
                this.succeeded = false;
            } finally {
                this.loading = false;
                this.isDone = true;
                this.shows = true;
            }
        },

        allowedDates(date) {
            if (this.form.type == PURCHASE_ORDER_TYPES.STORE_ORDER_BY_COMPANY.value) return [2, 3, 4, 5, 6].includes(dayjs(date).day());
            else return true;
        },
    },
};
</script>
