<template>
    <page-section>
        <h2 class="tit">상품</h2>
        <v-divider class="my-20" />
        <table class="table-default" v-for="item in order.products" :key="item._id">
            <tr>
                <th>상품명</th>
                <td>{{ item.name }}</td>
            </tr>
            <tr>
                <th>수량</th>
                <td>{{ item.quantity }}개</td>
            </tr>
        </table>
    </page-section>
</template>

<script>
import PageSection from "@/components/client/sub/page-section.vue";

export default {
    components: {
        PageSection,
    },
    props: {
        order: { type: Object, default: () => ({}) },
    },
};
</script>

<style>
</style>