<template>
    <v-dialog-default title="클레임" contentClass="align-self-start" persistent @closed="reset" ref="dialog">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on, loading }" />
        </template>

        <div>
            <v-select placeholder="상품" v-model="form._product" outlined hide-details :items="order.products || []" item-text="name" item-value="_id" class="mb-20" />
            <v-textarea placeholder="내용을 입력하세요." v-model="form.query" outlined hide-details no-resize rows="6" />
        </div>

        <div class="pt-20">
            <v-btn block outlined color="primary" class="v-size--xx-large" v-bind="{ loading }" @click="save">클레임</v-btn>
        </div>
    </v-dialog-default>
</template>

<script>
import api from "@/api";

import VDialogDefault from "@/components/plugins/vuetify/v-dialog-default.vue";

let initForm = () => ({
    _product: undefined,
    query: null,
});

export default {
    components: {
        VDialogDefault,
    },
    props: {
        order: { type: Object, default: () => ({}) },
    },
    data: () => ({
        loading: false,
        form: initForm(),
    }),
    methods: {
        reset() {
            this.form = initForm();
        },
        async save() {
            this.loading = true;
            try {
                let { _product, query } = this.form;
                await api.v1.me.purchaseOrders.claims.post({ _order: this.order._id, _product, query });
                alert("등록되었습니다");
                this.$refs["dialog"]?.close?.();
                this.$emit("posted");
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style>
</style>