var render = function () {
  var _vm$item$targetsAt, _vm$item$targetsAt$to, _vm$item, _vm$item$receiver;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('border-list-item', {
    attrs: {
      "to": "/mypage/purchase-orders/".concat(_vm.item._id)
    }
  }, [_c('div', {
    staticClass: "d-flex align-center justify-space-between"
  }, [_c('div', {
    staticStyle: {
      "width": "calc(100% - 88px)"
    }
  }, [_c('p', {
    staticClass: "page-text text-truncate"
  }, [_vm._v(_vm._s(_vm.item.typeTextShorten))]), _c('p', {
    staticClass: "page-text text-truncate"
  }, [_vm._v(_vm._s(_vm.item.number))]), _vm.showsTargetsAt ? _c('p', {
    staticClass: "page-text text-truncate"
  }, [_vm._v(_vm._s((_vm$item$targetsAt = _vm.item.targetsAt) === null || _vm$item$targetsAt === void 0 ? void 0 : (_vm$item$targetsAt$to = _vm$item$targetsAt.toDate) === null || _vm$item$targetsAt$to === void 0 ? void 0 : _vm$item$targetsAt$to.call(_vm$item$targetsAt)))]) : _vm._e(), _c('p', {
    staticClass: "page-text text-truncate"
  }, [_vm._v(_vm._s((_vm$item = _vm.item) === null || _vm$item === void 0 ? void 0 : (_vm$item$receiver = _vm$item.receiver) === null || _vm$item$receiver === void 0 ? void 0 : _vm$item$receiver.name))])]), _c('v-chip', {
    staticClass: "w-64px v-btn--none-active-bg",
    attrs: {
      "outlined": ""
    }
  }, [_c('span', {
    class: _vm.stateColor
  }, [_vm._v(_vm._s(_vm.item.stateText))])]), _c('v-icon', {
    staticClass: "mr-n8",
    attrs: {
      "size": "24"
    }
  }, [_vm._v("mdi-chevron-right")])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }